import { db } from '@/services/firebase'
import { useOrganizationStore } from '@/stores/organization'
import { CampaignStatus, Campaign, Log, Target, TargetEvent } from '@/types/campaign'
import { collection, doc, limit, orderBy, query } from 'firebase/firestore'
import { countBy, values } from 'lodash-es'
import * as Sentry from '@sentry/browser'
import {
  VueFirestoreDocumentData,
  VueFirestoreQueryData,
  _RefFirestore,
  useCollection,
  useDocument,
} from 'vuefire'

// This has to be of type any because vuefire is not setting the return type from useDocument properly
let campaign: _RefFirestore<VueFirestoreDocumentData<Campaign> | undefined>

let targets: _RefFirestore<VueFirestoreQueryData<Target> | undefined>

const targetsEvents: Record<
  string,
  _RefFirestore<VueFirestoreQueryData<TargetEvent> | undefined>
> = reactive({})

let logs: _RefFirestore<VueFirestoreQueryData<Log> | undefined>

const bindCampaign = (campaignId: string) => {
  const orgStore = useOrganizationStore()
  const orgId = orgStore.rootOrg.organization.id

  if (!orgId) throw new Error('Missing organization id')

  try {
    campaign = useDocument<Campaign>(doc(db, 'organizations', orgId, 'campaigns', campaignId))
  } catch (error: any) {
    Sentry.captureException(error, { extra: { orgId, function: 'bindCampaign' } })
  }
}

const bindCampaignLogs = async (campaignId: string) => {
  const orgStore = useOrganizationStore()
  const orgId = orgStore.rootOrg.organization.id

  if (!orgId) throw new Error('Missing organization id')

  logs = useCollection<Log>(
    query(
      collection(db, 'organizations', orgId, 'campaigns', campaignId, 'logs'),
      limit(15),
      orderBy('at', 'desc'),
    ),
    {
      maxRefDepth: 0,
      ssrKey: 'campaignLogs',
    },
  )
}

const bindTargets = (campaignId: string) => {
  const orgStore = useOrganizationStore()
  const orgId = orgStore.rootOrg.organization.id

  if (!orgId) throw new Error('Missing organization id')

  targets = useCollection<Target>(
    collection(db, 'organizations', orgId, 'campaigns', campaignId, 'targets'),
    {
      maxRefDepth: 0,
      ssrKey: 'targets',
    },
  )
}

const bindTargetEvents = (campaignId: string, targetId: string, targetPid: string) => {
  const orgStore = useOrganizationStore()
  const orgId = orgStore.rootOrg.organization.id

  if (!orgId) throw new Error('Missing organization id')

  const targetEvents = useCollection<TargetEvent>(
    query(
      collection(
        db,
        'organizations',
        orgId,
        'campaigns',
        campaignId,
        'targets',
        targetId,
        'events',
      ),
      orderBy('at', 'desc'),
    ),
    {
      maxRefDepth: 0,
      ssrKey: 'targetEvents',
    },
  )
  targetsEvents[targetPid] = targetEvents
}

const unbindAll = () => {
  campaign?.stop()
  logs?.stop()
  targets?.stop()
  // We use this to solve a reactivity issue
  if (campaign) campaign.value = undefined

  if (logs) logs.value = undefined

  if (targets) targets.value = undefined
}

const isCompleted = computed(() => campaign.value?.status === CampaignStatus.COMPLETED)
const isPending = computed(() => campaign.value?.status === CampaignStatus.PENDING)
const isProcessing = computed(() => campaign.value?.status === CampaignStatus.PROCESSING)
const isRunning = computed(() => campaign.value?.status === CampaignStatus.RUNNING)
const isAborted = computed(() => campaign.value?.status === CampaignStatus.ABORTED)
const runningAt = computed(() => campaign.value?.runningAt)
const processingAt = computed(() => campaign.value?.processingAt)

const wasPending = computed(() =>
  Boolean(
    isPending.value ||
      isRunning.value ||
      isProcessing.value ||
      isCompleted.value ||
      isAborted.value,
  ),
)
const wasRunning = computed(() =>
  Boolean(
    isRunning.value ||
      isProcessing.value ||
      isCompleted.value ||
      (isAborted.value && runningAt.value),
  ),
)
const wasProcessing = computed(() =>
  Boolean(isProcessing.value || isCompleted.value || (isAborted.value && processingAt.value)),
)

const targetPoolCountBy = computed(() =>
  countBy(values(campaign.value?.privateDataRef?.targetPool), Boolean),
)

const targetsCount = computed(() => campaign.value?.privateDataRef?.count?.campaign?.targets ?? 0)

export const useCampaignBinding = () => ({
  bindCampaign,
  bindTargets,
  bindTargetEvents,
  bindCampaignLogs,
  unbindAll,
  targetsCount,
  targetPoolCountBy,
  campaign,
  targetsEvents,
  isProcessing,
  isCompleted,
  isPending,
  isRunning,
  isAborted,
  wasPending,
  wasRunning,
  wasProcessing,
  targets,
  logs,
})
