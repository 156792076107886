import { z } from 'zod'
import { SubscriptionPlan } from '../organization/subscription'
import { TenantAccessType } from '../user'
import { partnerBillingApiSchema } from './billing'

const partnerPhishingQuotaApiSchema = z.object({
  expiresAt: z.string(),
  quota: z.number(),
  remaining: z.number(),
  used: z.number(),
})

const partnerQuotaApiSchema = z.object({
  phishing: partnerPhishingQuotaApiSchema.array(),
})

const partnerSettingsApiSchema = z.object({
  security: z.object({
    mfa: z.object({
      isEnforced: z.boolean(),
    }),
    sso: z.object({
      isEnforced: z.boolean(),
    }),
  }),
})

export const partnerOrganizationApiSchema = z.object({
  id: z.string(),
  name: z.string(),
  domain: z.string().nullable(),
  tenantAccess: z.object({
    type: z.nativeEnum(TenantAccessType),
    // @FIXME: remove optional once we are sure that all tenants have permissionId
    permissionId: z.string().optional(),
  }),
  subscription: z.object({
    plan: z.nativeEnum(SubscriptionPlan),
  }),
})

export const partnerApiSchema = z.object({
  id: z.string(),
  name: z.string(),
  billing: partnerBillingApiSchema.nullable(),
  quota: partnerQuotaApiSchema,
  settings: partnerSettingsApiSchema,
  organizations: z.array(partnerOrganizationApiSchema),
  allOrganizations: z.array(partnerOrganizationApiSchema.pick({ id: true, name: true })),
})

export type PartnerApi = z.infer<typeof partnerApiSchema>

export type PartnerOrganizationApi = z.infer<typeof partnerOrganizationApiSchema>

export type PartnerPhishingQuotaApi = z.infer<typeof partnerPhishingQuotaApiSchema>

export type PartnerSettingsApi = z.infer<typeof partnerSettingsApiSchema>

export type PartnerQuotaApi = z.infer<typeof partnerQuotaApiSchema>
