import { useTenant } from '@/api/user'
import { useAbility } from '@/plugins/casl'
import { i18n } from '@/plugins/i18n'
import { router } from '@/router'
import { auth, storage } from '@/services/firebase'
import { OrganizationMemberPermission, TenantAccessType } from '@/types/user'
import { refreshToken } from '@/utils/api'
import { Crisp } from 'crisp-sdk-web'
import { signOut } from 'firebase/auth'
import { getDownloadURL, ref } from 'firebase/storage'
import iziToast from 'izitoast'
import { defineStore } from 'pinia'
import { useMyselfStore } from './myself'
import { useOrganizationStore } from './organization'
import { usePartnerStore } from './partner'
import { useWalkthroughStore } from './walkthrough'

export const useAppStore = defineStore('app', {
  state: () => ({
    webClientIsOutdated: false,
    permissionId: OrganizationMemberPermission.VIEWER,
    organizationLogo: undefined as string | undefined,
    hasLoggedIn: false,
    displayLoader: false,
  }),
  actions: {
    async destroy() {
      // Hard redirect to login page (allow reset of Pinia store and snapshot listeners)
      const path = router.resolve({ name: 'auth-login' })

      console.log('[destroy] Start app/destroy...')

      // Logout with firebase auth
      await signOut(auth)
      localStorage.removeItem('api-token')

      refreshToken()

      const { update } = useAbility()

      update()

      window.location.href = `${window.location.origin}${path.href}`
      Crisp.session.reset()

      router.push({ name: 'auth-login' })
    },

    async useTenant(data: { as: TenantAccessType; to: string }) {
      console.log('[auth/useTenant] Use tenant...', data)

      // Call use tenant and force refresh JWT token via Firebase Authentication
      try {
        await useTenant(data)
        await this.initUser()
      } catch (error: any) {
        console.log('[auth/useTenant] An error occurred...', error.message)
      }
    },

    async init() {
      console.log('[init] Start app/init...')

      // If user is not logged in, redirect to login page
      if (!auth.currentUser) {
        console.log('[init] No user found, redirecting to login...')
        return
      }

      // Initialize user profile data
      try {
        await this.initUser()
        // User is supposed to be fetched at this point
      } catch (error: any) {
        console.log('[init] An error occurred while initializing user...', error.message)

        throw error
      }

      this.initACL()

      const myselfStore = useMyselfStore()
      // Initialize tenant claims and either orga or partner data depending on the current tenant type
      if (myselfStore.isLoggedInAsOrganization) {
        await this.initOrganization()
        // Organization is supposed to be fetched at this point
      } else if (myselfStore.isLoggedInAsPartner) {
        await this.initPartner()
      }

      try {
        await this.initCrisp()
      } catch (error: any) {
        console.log('[init] An error occurred...', error.message)

        iziToast.error({
          title: 'Oops! 😣',
          message: i18n.global.t(error.message),
          progressBar: true,
        })
      }

      console.log('[init] App initialized successfully!')
    },

    initACL() {
      const { update } = useAbility()

      update()
    },

    // loads crisp asynchronously
    async initCrisp() {
      const myselfStore = useMyselfStore()

      console.log('[initCrisp] Start app/initCrisp...')
      const user = myselfStore.myself

      if (!user) return

      const userNickname = `${user.firstName} ${user.lastName}`

      Crisp.user.setEmail(user.email ?? '', myselfStore.myself?.customerSupportToken)
      Crisp.user.setNickname(userNickname)
      Crisp.session.setData({ user_id: user.id })

      if (user.belongsTo === TenantAccessType.ORGANIZATION) {
        const orgStore = useOrganizationStore()
        const { organization } = orgStore

        if (!organization) return

        const userCompany = organization.name ?? ''
        // Just after organization creation, domain might not be set yet
        // Using ternary operator to avoid undefined error
        const userCompanyUrl = organization.domains?.[0]?.name
          ? `https://${organization.domains[0].name}`
          : undefined
        const organizationId = organization.id

        console.log(userCompany, { url: userCompanyUrl })

        Crisp.user.setCompany(userCompany, { url: userCompanyUrl })
        Crisp.session.setData({ organization_id: organizationId })

        Crisp.session.setSegments([organization.subscription.plan.toLowerCase()], true)
      }

      if (user.belongsTo === TenantAccessType.PARTNER) {
        const partner = myselfStore.myself?.accessTo.find(
          (tenant) => tenant.type === TenantAccessType.PARTNER,
        )

        if (!partner) return

        const partnerName = partner.name ?? ''
        const partnerId = partner.id

        Crisp.user.setCompany(partnerName, {})
        Crisp.session.setData({ partner_id: partnerId })
      }

      Crisp.session.setSegments(['support'], false)
    },

    async initPartner() {
      console.log('[init#initPartner] Start app/initPartner...')

      const myselfStore = useMyselfStore()

      const isUsingTenant = myselfStore.myself?.loggedInTo !== null
      if (!isUsingTenant) {
        throw new Error(i18n.global.t('errors.login'))
      }

      const partnerId = myselfStore.myself?.loggedInTo?.id

      if (!partnerId) {
        throw new Error(i18n.global.t('errors.login'))
      }

      const partnerStore = usePartnerStore()
      await partnerStore.setPartnerFromApi()
    },

    async initOrganization() {
      console.log('[init#initOrganization] Start app/initOrganization...')

      const myselfStore = useMyselfStore()
      const myself = myselfStore.myself

      const userId = myself?.id
      const isUsingTenant = myself?.loggedInTo !== null

      if (!isUsingTenant) {
        throw new Error(i18n.global.t('errors.login'))
      }

      const organizationId = myself?.loggedInTo?.id
      const permissionId = myself?.loggedInTo?.permissionId

      if (!organizationId || !permissionId) {
        throw new Error(i18n.global.t('errors.login'))
      }

      const orgStore = useOrganizationStore()

      await orgStore.setOrganizationFromApi()

      const organization = orgStore.data

      if (!organization || !userId) return

      if (orgStore.organization?.isWalkthroughMarkedAsCompleted === false) {
        const walkthroughStore = useWalkthroughStore()
        await walkthroughStore.setWalkthroughFromApi()
      }

      let role = OrganizationMemberPermission.VIEWER

      switch (myself.loggedInTo?.permissionId) {
        case 'ownerAttributes': {
          role = OrganizationMemberPermission.OWNER
          break
        }

        case 'adminAttributes': {
          role = OrganizationMemberPermission.ADMIN
          break
        }

        case 'viewerAttributes': {
          role = OrganizationMemberPermission.VIEWER
        }
      }

      this.permissionId = role

      try {
        const url = await getDownloadURL(
          ref(storage, `organizations/${organizationId}/account/logo.png`),
        )

        this.setAvatar(url)
      } catch {
        console.log('No logo found, skipping avatar setup')
      }
    },

    async initUser() {
      console.log('[init#initUser] Start app/initUser...')

      const myselfStore = useMyselfStore()

      await myselfStore.setMyselfFromApi()

      console.log('[init#initUser] User fetched...', myselfStore.myself)
    },

    setAvatar(url: string) {
      if (url) {
        Crisp.user.setAvatar(url)
      }
      this.organizationLogo = url
    },
  },
})
