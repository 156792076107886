import { Organization } from '@/types/organization'
import { PartnerApi, partnerApiSchema } from '@/types/partner'
import {
  PartnerBillingInvoiceApi,
  generatePartnerOrderQuotaUrlApiSchema,
  listPartnerInvoicesApiSchema,
} from '@/types/partner/billing'
import { apiFetch } from '@/utils/api'
import { throwApiError, validateObject } from '@/utils/api-data'

export const addOrganizationToPartner = async (domain: string) => {
  try {
    await apiFetch<{ organization: Organization }>('/add-organization-to-partner', {
      method: 'POST',
      body: { domain },
    })
  } catch (error) {
    return throwApiError(error)
  }
}

export const fetchPartner = async () => {
  try {
    const { partner } = await apiFetch<{ partner: PartnerApi }>('/read-partner', {
      method: 'GET',
    })

    return validateObject(partnerApiSchema, partner)
  } catch (error) {
    return throwApiError(error)
  }
}

export const fetchPartnerInvoices = async () => {
  try {
    const apiResponse = await apiFetch<PartnerBillingInvoiceApi[]>('/list-partner-invoices', {
      method: 'GET',
    })

    return validateObject(listPartnerInvoicesApiSchema, apiResponse)
  } catch (error) {
    return throwApiError(error)
  }
}

/**
 * Credits is (for now) a frontend-only name for the partner's quota.
 * We assume that the `quota` parameter is the number of credits that the partner wants to buy.
 * @todo we should decide if we want to keep the name `credits` or change it to `quota` in the frontend.
 */
export const generatePartnerOrderQuotaUrl = async (quota: number) => {
  try {
    const apiResponse = await apiFetch<{ externalPaymentUrl: string }>(
      '/generate-partner-order-quota-url',
      {
        method: 'POST',
        body: {
          quota,
        },
      },
    )

    return validateObject(generatePartnerOrderQuotaUrlApiSchema, apiResponse)
  } catch (error) {
    return throwApiError(error)
  }
}

export const deleteOrganization = async (organizationId: string) => {
  try {
    await apiFetch('/delete-organization', {
      method: 'DELETE',
      body: {
        organizationId,
      },
    })
  } catch (error) {
    return throwApiError(error)
  }
}

export const updatePartnerName = async (name: string) => {
  try {
    await apiFetch('/update-partner-name', {
      method: 'POST',
      body: {
        name,
      },
    })
  } catch (error) {
    return throwApiError(error)
  }
}

export const updatePartnerBillingEmailAddress = async (email: string) => {
  try {
    await apiFetch('/update-partner-billing-email', {
      method: 'POST',
      body: {
        email,
      },
    })
  } catch (error) {
    return throwApiError(error)
  }
}

export const updatePartnerMfaEnforcement = async (isEnforced: boolean) => {
  try {
    return await apiFetch('/update-partner-mfa-enforcement', {
      method: 'POST',
      body: { isEnforced },
    })
  } catch (error) {
    return throwApiError(error)
  }
}

export const updatePartnerSsoEnforcement = async (isEnforced: boolean) => {
  try {
    return await apiFetch('/update-partner-sso-enforcement', {
      method: 'POST',
      body: { isEnforced },
    })
  } catch (error) {
    return throwApiError(error)
  }
}
