import { fetchPartner } from '@/api/partner'
import { PartnerMapper } from '@/mappers/partner'
import { PartnerApi } from '@/types/partner'
import { defineStore } from 'pinia'

/**
 * @deprecated
 * Should be replace by a composable/domain: Partner
 */
export const usePartnerStore = defineStore('partner', {
  state: () => ({
    partner: PartnerMapper.toDomain(),
  }),
  getters: {
    /**
     * @refactor
     * update api response to return a single number of remaining quota instead of an array
     */
    totalValidQuotaForPhishing: (state) =>
      state.partner.quota.phishing
        .filter((phishingQuota) => phishingQuota.expiresAt > new Date().toISOString())
        .reduce((acc, curr) => acc + curr.remaining, 0),
  },
  actions: {
    setPartner(data: PartnerApi) {
      this.partner = PartnerMapper.toDomain(data)
    },
    async setPartnerFromApi() {
      try {
        const partner = await fetchPartner()

        this.setPartner(partner)
      } catch (error: any) {
        throw new Error('Error while fetching partner', error)
      }
    },
  },
})
