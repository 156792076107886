import { Action, Subject } from '@/types/acl'
import type { RouteRecordRaw } from 'vue-router'

export default <RouteRecordRaw[]>[
  {
    path: '/employees',
    name: 'employee',
    redirect: {
      name: 'employee-list',
    },
    meta: {
      subject: Subject.EMPLOYEE,
      action: Action.LIST,
    },
    children: [
      {
        path: '',
        name: 'employee-list',
        component: () => import('@/pages/EmployeeListPage.vue'),
        meta: {
          subject: Subject.EMPLOYEE,
          action: Action.LIST,
        },
      },
      {
        path: ':employeeId',
        name: 'employee-view',
        component: () => import('@/pages/EmployeeViewPage.vue'),
        meta: {
          subject: Subject.EMPLOYEE,
          action: Action.READ,
        },
      },
    ],
  },
]
