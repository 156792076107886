import { createRouter, createWebHistory, LocationQuery } from 'vue-router'

import { setupLayouts } from 'virtual:generated-layouts'
import routes from './routes'
import { logEvent } from '@firebase/analytics'
import { analytics } from '@/services/firebase'
import { authGuard } from './auth-guard'
import qs from 'qs'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),

  scrollBehavior: () => ({ top: 0, left: 0 }),

  parseQuery: (query) => qs.parse(query) as LocationQuery,

  stringifyQuery: (query) => {
    const result = qs.stringify(query)

    return result ?? ''
  },

  routes: [...setupLayouts(routes)],
})

router.beforeEach(authGuard)

router.afterEach((to) => {
  // Send analytics event on page change
  logEvent(analytics, 'page_view', {
    page_title: String(to.name) ?? to.path,
    page_path: to.path,
  })
})

export { router }
