import { z } from 'zod'

export const whitelistingInstructionsSchema = z.object({
  arsen: z.object({
    ipAddresses: z.string().array(),
    domains: z.string().array(),
  }),
  token: z.object({
    expiresAt: z.string(),
  }),
  organization: z.object({
    name: z.string(),
    xHeader: z.string(),
    emailProvider: z.string().nullable(),
    domains: z
      .object({
        name: z.string(),
        isVerified: z.boolean(),
      })
      .array(),
  }),
})
export type WhitelistingInstructions = z.infer<typeof whitelistingInstructionsSchema>
