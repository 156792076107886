import type { RouteLocationNormalized } from 'vue-router'
import type { NavGroup } from '@layouts/types'
import { useAbility } from '@/plugins/casl'

/**
 * Returns ability result if ACL is configured or else just return true
 * We should allow passing string | undefined to can because for admin ability we omit defining action & subject
 *
 * Useful if you don't know if ACL is configured or not
 * Used in @core files to handle absence of ACL without errors
 *
 * @param {String} action CASL Actions // https://casl.js.org/v4/en/guide/intro#basics
 * @param {String} subject CASL Subject // https://casl.js.org/v4/en/guide/intro#basics
 */
export const can = (
  action: string | undefined = '',
  subject: string[] | string | undefined = '',
) => {
  const { ability } = useAbility()

  if (Array.isArray(subject)) {
    return subject.some((s) => ability.can(action, s))
  }

  return ability.can(action, subject)
}

/**
 * Check if user can view item based on it's ability
 * Based on item's action and subject & Hide group if all of it's children are hidden
 * @param {Object} item navigation object item
 */
export const canViewNavMenuGroup = (item: NavGroup) => {
  const { ability } = useAbility()

  const hasAnyVisibleChild = item.children.some((i) => can(i.action, i.subject))

  // If subject and action is defined in item => Return based on children visibility (Hide group if no child is visible)
  // Else check for ability using provided subject and action along with checking if has any visible child
  if (!(item.action && item.subject)) {
    return hasAnyVisibleChild
  }

  return ability.can(item.action, item.subject) && hasAnyVisibleChild
}

export const canNavigate = (to: RouteLocationNormalized) =>
  to.matched.some(
    (route) => !route.meta.action || can(route.meta.action ?? 'read', route.meta.subject),
  )
