import type { RouteRecordRaw } from 'vue-router'
import { Action, Subject } from '@/types/acl'

export default <RouteRecordRaw[]>[
  {
    path: '/schedulers',
    name: 'scheduler',
    redirect: {
      name: 'scheduler-list',
    },
    meta: {
      action: Action.LIST,
      subject: Subject.CAMPAIGN,
    },
    children: [
      {
        path: '',
        name: 'scheduler-list',
        component: () => import('@/pages/RecurringCampaignSchedulerListPage.vue'),
        meta: {
          subject: Subject.CAMPAIGN,
          action: Action.LIST,
        },
      },
      {
        path: ':campaignId',
        name: 'scheduler-view',
        component: () => import('@/pages/RecurringCampaignSchedulerViewPage.vue'),
        meta: {
          subject: Subject.CAMPAIGN,
          action: Action.READ,
        },
      },
    ],
  },
]
