import { z } from 'zod'

export const domainSchema = z.object({
  id: z.string(),
  name: z.string(),
  organizationId: z.string(),
  isVerified: z.boolean(),
  dns: z.nullable(
    z.array(
      z.object({
        currentValue: z.string().optional().nullable(),
        hostname: z.string().optional(),
        isVerified: z.boolean().optional(),
        type: z.string().optional(),
        value: z.string().optional(),
      }),
    ),
  ),
})

export type Domain = z.infer<typeof domainSchema>
