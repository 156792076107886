import { organizationRepository } from '@/modules/TrainingAwareness/infra/adapters/organization.repository'
import { Action, Subject } from '@/types/acl'
import { RouteRecordRaw } from 'vue-router'

export const trainingAwarenessListLessonsRoute: RouteRecordRaw = {
  /**
   * Parent path: /courses
   * Defined in src/modules/TrainingAwareness/app/routes.ts
   */
  path: '',
  name: 'training-awareness-lesson-list',
  component: () => import('./ListLessonPage.vue'),
  props: (route) => ({ organization: route.meta.organization }),
  meta: {
    action: Action.READ,
    subject: Subject.TRAINING_AWARENESS,
  },
  beforeEnter: async (to, from, next) => {
    try {
      const organization = await organizationRepository.readOrganization()

      if (organization.isTrainingAwarenessEnabled) {
        next()
      } else {
        next({ name: 'feature-wall-training-awareness' })
      }

      to.meta.organization = organization
      next()
    } catch (error: any) {
      if (error.status === 404) {
        next({ name: 'feature-wall-training-awareness' })
      }
      throw error
    }
  },
}
