import { z } from 'zod'

export const organizationSettingsSchema = z.object({
  security: z.object({
    mfa: z.object({
      isEnforced: z.boolean(),
    }),
    sso: z.object({
      isEnforced: z.boolean(),
    }),
  }),
  phishing: z.object({
    email: z.object({
      xHeader: z.string(),
    }),
  }),
  campaigns: z.object({
    autoTerminationDuration: z.number(),
  }),
})

export type OrganizationSettings = z.infer<typeof organizationSettingsSchema>
