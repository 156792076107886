import { Crisp } from 'crisp-sdk-web'

Crisp.configure(import.meta.env.VITE_CRISP_ID, {
  autoload: true,
  sessionMerge: true,
})

Crisp.setZIndex(10)

Crisp.load()
