import { SubscriptionPlan } from '@/types/organization/subscription'
import {
  PartnerApi,
  PartnerOrganizationApi,
  PartnerPhishingQuotaApi,
  PartnerQuotaApi,
  PartnerSettingsApi,
} from '@/types/partner'
import { OrganizationMemberPermission, TenantAccessType } from '@/types/user'

/**
 * We assume here that if `partner` param is not defined, it means that the entire app is in a loading state.
 * Once the loading screen disappears, the Partner should be set.
 * And we can access the Partner object from the store (without the need to check if it's defined).
 *
 * @todo
 * Replace return types `Partner*Api` with `Partner*` types
 */
export const PartnerMapper = {
  toDomain: (partner?: PartnerApi): PartnerApi => {
    return {
      id: partner?.id ?? '',
      name: partner?.name ?? '',
      billing: partner?.billing ?? null,
      quota: PartnerQuotaMapper.toDomain(partner?.quota),
      settings: PartnerSettingsMapper.toDomain(partner?.settings),
      organizations: PartnerOrganizationsMapper.toDomain(partner?.organizations),
      allOrganizations: partner?.allOrganizations ?? [],
    }
  },
}

export const PartnerOrganizationsMapper = {
  toDomain: (organizations?: PartnerOrganizationApi[]): PartnerOrganizationApi[] =>
    organizations?.map?.((organization) => PartnerOrganizationMapper.toDomain(organization)) ?? [],
}

export const PartnerOrganizationMapper = {
  toDomain: (organization?: PartnerOrganizationApi): PartnerOrganizationApi => {
    return {
      id: organization?.id ?? '',
      name: organization?.name ?? '',
      domain: organization?.domain ?? null,
      tenantAccess: {
        type: organization?.tenantAccess?.type ?? TenantAccessType.ORGANIZATION,
        permissionId:
          organization?.tenantAccess?.permissionId ?? OrganizationMemberPermission.VIEWER,
      },
      subscription: {
        plan: organization?.subscription?.plan ?? SubscriptionPlan.ON_DEMAND,
      },
    }
  },
}

export const PartnerQuotaMapper = {
  toDomain: (quota?: PartnerQuotaApi): PartnerQuotaApi => {
    return {
      phishing:
        quota?.phishing?.map?.((phishingQuota: PartnerPhishingQuotaApi) => ({
          expiresAt: phishingQuota?.expiresAt ?? '',
          quota: phishingQuota?.quota ?? 0,
          remaining: phishingQuota?.remaining ?? 0,
          used: phishingQuota?.used ?? 0,
        })) ?? [],
    }
  },
}

export const PartnerSettingsMapper = {
  toDomain: (settings?: PartnerSettingsApi): PartnerSettingsApi => {
    return {
      security: {
        mfa: {
          isEnforced: settings?.security?.mfa?.isEnforced ?? false,
        },
        sso: {
          isEnforced: settings?.security?.sso?.isEnforced ?? false,
        },
      },
    }
  },
}
