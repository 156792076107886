import { TenantAccessType, User, userSchema } from '@/types/user'
import { Myself, myselfSchema } from '@/types/user/myself'
import { apiFetch, refreshToken } from '@/utils/api'
import { throwApiError, validateObject } from './../utils/api-data'

export const fetchMyself = async (): Promise<Myself> => {
  try {
    const res = await apiFetch<{ user: Myself }>('/read-myself')

    return validateObject(myselfSchema, res.user)
  } catch (error) {
    return throwApiError(error)
  }
}

export const updateUser = async (data: Partial<User>): Promise<User> => {
  try {
    const { user } = await apiFetch<{ user: User }>('/update-user', {
      method: 'POST',
      body: data,
    })

    return validateObject(userSchema, user)
  } catch (error) {
    return throwApiError(error)
  }
}

export const updateUserEmail = async (email: string): Promise<User> => {
  try {
    const { user } = await apiFetch('/update-user-email', {
      method: 'POST',
      body: { email },
    })

    return validateObject(userSchema, user)
  } catch (error) {
    return throwApiError(error)
  }
}

export const useTenant = async (data: { as: TenantAccessType; to: string }) => {
  try {
    await apiFetch('/use-tenant-access', { method: 'POST', body: data })
    await refreshToken()
  } catch (error) {
    return throwApiError(error)
  }
}
