import {
  TenantAccessType,
  userSchema,
  OrganizationMemberPermission,
  PartnerMemberPermission,
} from './index'
import { z } from 'zod'
import { accessToSchema } from './accessTo'

export const myselfSchema = z.object({
  ...userSchema.shape,
  customerSupportToken: z.string(),
  isMfaEnrolled: z.boolean(),
  isSsoEnabled: z.boolean(),
  accessTo: z.array(accessToSchema),
  belongsTo: z.nativeEnum(TenantAccessType),
  loggedInTo: z
    .object({
      id: z.string(),
      name: z.string(),
      type: z.nativeEnum(TenantAccessType),
      permissionId: z
        .union([z.nativeEnum(OrganizationMemberPermission), z.nativeEnum(PartnerMemberPermission)])
        .optional(),
      // Optional for Partner
      searchEngineEmployeesScopedKey: z.string().optional(),
    })
    .nullable(),
})

export type Myself = z.infer<typeof myselfSchema>
