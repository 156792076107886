import { cyberThreatIntelligenceRoutes } from '@/modules/CyberThreatIntelligence/app/routes'
import { smishingRoutes } from '@/modules/Smishing/app/routes'
import { trainingAwarenessRoutes } from '@/modules/TrainingAwareness/app/routes'
import { vishingRoutes } from '@/modules/Vishing/app/routes'
import { useMyselfStore } from '@/stores/myself'
import { Action, Subject } from '@/types/acl'
import accountRoutes from '@modules/Account/routes'
import authRoutes from '@modules/Auth/routes'
import campaignRoutes from '@modules/Campaign/routes'
import dashboardRoutes from '@modules/Dashboard/routes'
import employeeRoutes from '@modules/Employee/routes'
import groupRoutes from '@modules/Group/routes'
import organizationRoutes from '@modules/Organization/routes'
import partnerRoutes from '@modules/Partner/routes'
import recurringCampaignRoutes from '@modules/RecurringCampaignScheduler/routes'
import scenarioRoutes from '@modules/Scenario/routes'
import whitelistRoutes from '@modules/Whitelist/routes'
import { RouteRecordRaw } from 'vue-router'
import { router } from '.'

export default <RouteRecordRaw[]>[
  ...accountRoutes,
  ...organizationRoutes,
  ...employeeRoutes,
  ...dashboardRoutes,
  ...authRoutes,
  ...partnerRoutes,
  ...whitelistRoutes,
  ...scenarioRoutes,
  ...campaignRoutes,
  ...recurringCampaignRoutes,
  ...groupRoutes,
  ...cyberThreatIntelligenceRoutes,
  ...smishingRoutes,
  ...vishingRoutes,
  ...trainingAwarenessRoutes,

  {
    path: '/pricing',
    name: 'pricing',
    component: () => import('@/pages/PricingPage.vue'),
    meta: {
      subject: Subject.PRICING,
      action: Action.READ,
    },
  },
  {
    path: '/',
    name: 'dispatcher',
    meta: {
      action: Action.READ,
      subject: Subject.DISPATCHER,
    },
    beforeEnter: async () => {
      console.log('router.dispatcher#beforeEnter')

      const myselfStore = useMyselfStore()

      // If tenant selected or have to select one, go to dashboard
      if (myselfStore.isLoggedInAsOrganization || myselfStore.isTenantLess) {
        console.log('router.dispatcher: redirecting to organization dashboard...')
        window.location.assign(router.resolve({ name: 'dashboard' }).href)
      } else if (myselfStore.isLoggedInAsPartner) {
        console.log('router.dispatcher: redirecting to partner dashboard...')
        window.location.assign(router.resolve({ name: 'partner-dashboard' }).href)
      } else {
        // Fallback to dashboard if unknown log in state
        console.log('[FALLBACK] router.dispatcher: redirecting dashboard...')
        window.location.assign(router.resolve({ name: 'dashboard' }).href)
      }
    },
  },

  {
    path: '/404',
    name: '404',
    component: () => import('@/pages/Error404Page.vue'),
    meta: {
      layout: 'basic',
      subject: Subject.ERROR,
      action: Action.READ,
    },
  },

  {
    path: '/error',
    name: 'error',
    component: () => import('@/pages/ErrorGenericPage.vue'),
    meta: {
      layout: 'basic',
      subject: Subject.ERROR,
      action: Action.READ,
    },
  },

  {
    path: '/:pathMatch(.*)*',
    redirect: { name: '404' },
  },
]
