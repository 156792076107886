import { Action, Subject } from '@/types/acl'
import { RouteRecordRaw } from 'vue-router'

export default <RouteRecordRaw[]>[
  {
    path: '/organization',
    redirect: { name: 'organization-general' },
    name: 'organization',
    component: () => import('@/pages/OrganizationPage.vue'),
    meta: {
      action: Action.READ,
      subject: Subject.ORGANIZATION_GENERAL,
    },
    children: [
      {
        path: 'general',
        name: 'organization-general',
        component: () => import('./components/OrganizationGeneral'),
        meta: {
          subject: Subject.ORGANIZATION_GENERAL,
          action: Action.READ,
        },
      },
      {
        path: 'security',
        name: 'organization-security',
        redirect: { name: 'organization-security-view' },
      },
      {
        path: 'security/:tab?',
        name: 'organization-security-view',
        component: () => import('./components/OrganizationSecurity'),
        meta: {
          subject: Subject.ORGANIZATION_SECURITY,
          action: Action.UPDATE,
        },
      },
      {
        path: 'subscription',
        name: 'organization-subscription',
        component: () => import('./components/OrganizationSubscription'),
        meta: {
          subject: Subject.ORGANIZATION_SUBSCRIPTION,
          action: Action.READ,
        },
      },
      {
        path: 'team',
        name: 'organization-team-list',
        component: () => import('./components/OrganizationTeam'),
        meta: {
          subject: Subject.ORGANIZATION_TEAM,
          action: Action.LIST,
        },
      },
      {
        path: 'domains',
        name: 'organization-domain-list',
        component: () => import('./components/OrganizationDomains'),
        meta: {
          subject: Subject.ORGANIZATION_DOMAIN,
          action: Action.LIST,
        },
      },
      {
        path: 'domains/:domainId',
        name: 'organization-domain-view',
        component: () => import('./components/OrganizationDomainsView'),
        props: true,
        meta: {
          subject: Subject.ORGANIZATION_DOMAIN,
          action: Action.READ,
        },
      },
      {
        path: 'campaigns-settings',
        name: 'organization-campaigns-settings',
        component: () => import('./components/OrganizationCampaignsSettings'),
        meta: {
          subject: Subject.ORGANIZATION_CAMPAIGNS_SETTINGS,
          action: Action.READ,
        },
      },
      {
        path: 'phishing-domains',
        name: 'organization-phishing-domain-list',
        component: () => import('./components/OrganizationPhishingDomains'),
        meta: {
          subject: Subject.ORGANIZATION_PHISHING_DOMAIN,
          action: Action.LIST,
        },
      },
      {
        path: 'integrations',
        name: 'organization-integrations',
        component: () => import('./components/OrganizationIntegrations'),
        meta: {
          subject: Subject.ORGANIZATION_INTEGRATIONS,
          action: Action.LIST,
        },
      },
      {
        path: 'integrations/:integrationPath/:tab?',
        name: 'organization-integrations-view',
        component: () => import('./components/OrganizationIntegrationsView'),
        props: true,
        meta: {
          subject: Subject.ORGANIZATION_INTEGRATIONS,
          action: Action.READ,
        },
      },
    ],
  },
]
