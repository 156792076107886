import { initializeFirestore, connectFirestoreEmulator } from 'firebase/firestore'
import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions'
import { getAuth, connectAuthEmulator } from 'firebase/auth'
import { getStorage } from 'firebase/storage'
import { appConfig, firebaseConfig } from '@/config'

// Initialize Firebase
const fb = initializeApp(firebaseConfig)
const storage = getStorage()
const functions = getFunctions(fb, 'europe-west3')
const auth = getAuth()
const db = initializeFirestore(fb, { experimentalForceLongPolling: true })

const analytics = getAnalytics()

if (import.meta.env.DEV) {
  if (appConfig.useFirestoreEmulator) {
    connectFirestoreEmulator(db, 'localhost', 8088)
  }
  if (appConfig.useFunctionsEmulator) {
    connectFunctionsEmulator(functions, 'localhost', 5001)
  }
  if (appConfig.useAuthEmulator) {
    connectAuthEmulator(auth, 'http://localhost:9099')
  }
}

auth.onIdTokenChanged(async (user) => {
  if (user) {
    console.log(`[onIdTokenChanged] New ID token for ${user.uid}...`)
  }
})

export { fb, db, auth, storage, functions, analytics }
