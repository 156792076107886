import { isProduction } from '@/utils/env'
import { init } from '@sentry/vue'
import type { App } from 'vue'

export const initSentry = (app: App) => {
  init({
    app,
    environment: isProduction ? 'production' : 'development',
    dsn: import.meta.env.VITE_SENTRY_DSN,
    enabled: import.meta.env.PROD,
  })
}
