import { z } from 'zod'
import { OrganizationMemberPermission } from '../user'

const organizationPersonSchema = {
  permissionId: z.nativeEnum(OrganizationMemberPermission),
  firstName: z.string(),
  lastName: z.string(),
  email: z.string(),
}

export const organizationTeamMemberSchema = z.object({
  ...organizationPersonSchema,
  id: z.string(),
  provider: z.enum(['password', 'google', 'microsoft']),
  isMfaEnrolled: z.boolean(),
})

export const organizationTeamInviteeSchema = z.object({
  ...organizationPersonSchema,
  id: z.null().default(null),
  provider: z.null().default(null),
  isMfaEnrolled: z.null().default(null),
})

export const verifyJoinOrganizationTokenSchema = z.object({
  invitee: organizationTeamInviteeSchema.pick({
    firstName: true,
    lastName: true,
    email: true,
    permissionId: true,
  }),
  organization: z.object({
    name: z.string(),
    isSsoEnforced: z.boolean(),
  }),
})

export type OrganizationTeamPerson = z.infer<
  typeof organizationTeamMemberSchema | typeof organizationTeamInviteeSchema
>
export type OrganizationTeamMember = z.infer<typeof organizationTeamMemberSchema>
export type OrganizationTeamInvitee = z.infer<typeof organizationTeamInviteeSchema>
export type VerifyJoinOrganizationTokenResponse = z.infer<typeof verifyJoinOrganizationTokenSchema>
