import iziToast, { IziToastSettings } from 'izitoast'
import { useThemeConfig } from '@/@core/composable/useThemeConfig'
import { watch } from 'vue'

const { theme } = useThemeConfig()

const defaultOptions: IziToastSettings = {
  position: 'bottomCenter',
  theme: theme.value,
  progressBar: true,
  timeout: 4000,
}

iziToast.settings(defaultOptions)

watch(theme, (theme) => iziToast.settings({ theme }))
