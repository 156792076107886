import { db } from '@/services/firebase'
import { useAppStore } from '@/stores/app'
import { doc, onSnapshot } from '@firebase/firestore'
import type { DocumentData } from '@firebase/firestore'

export const useObservers = () => {
  const appStore = useAppStore()

  const observesWebClientReleaseVersion = async () => {
    let webClient: any

    const onNewRelease = (snapshot: DocumentData) => {
      const _webClient = snapshot.get('_webClient')

      if (_webClient) {
        if (webClient && !_webClient.isEqual(webClient)) {
          // Force a refresh of the user's ID token
          // console.log('[listenRelease] New release detected...')
          appStore.webClientIsOutdated = true
        }
        webClient = _webClient
      }
    }

    const docRef = doc(db, 'metadata', 'release')

    return onSnapshot(docRef, onNewRelease)
  }

  return { observesWebClientReleaseVersion }
}
