import {
  VerifyJoinPartnerTokenResponse,
  verifyJoinPartnerTokenSchema,
} from './../types/partner/team'
import { analytics, auth } from '@/services/firebase'
import { logEvent } from 'firebase/analytics'
import {
  browserSessionPersistence,
  indexedDBLocalPersistence,
  setPersistence,
  signInWithCustomToken,
  signInWithEmailAndPassword,
} from 'firebase/auth'

import { useAppStore } from '@/stores/app'
import {
  OrganizationTeamInvitee,
  organizationTeamInviteeSchema,
  VerifyJoinOrganizationTokenResponse,
  verifyJoinOrganizationTokenSchema,
} from '@/types/organization/team'
import { apiFetch } from '@/utils/api'
import { throwApiError, validateObject } from '@/utils/api-data'

export const loginWithPassword = async (email: string, password: string, usingPublic: boolean) => {
  try {
    const { token } = await apiFetch('/log-in-user', {
      method: 'POST',
      body: { email, password },
    })

    const persistence = usingPublic ? browserSessionPersistence : indexedDBLocalPersistence

    await signInWithCustomToken(auth, token)
    await setPersistence(auth, persistence)
  } catch (error: any) {
    return throwApiError(error)
  }
}

export const loginWithIdp = async (token: string) => {
  try {
    await signInWithCustomToken(auth, token)
  } catch (error: any) {
    return throwApiError(error)
  }
}

export const loginWithTotp = async (data: {
  email: string
  password: string
  usingPublic: boolean
  token: string
}) => {
  try {
    console.log('[login] Start logging-in...')

    const { token } = await apiFetch<{ token: string }>('/mfa', { body: data, method: 'POST' })

    logEvent(analytics, 'login')
    const persistence = data.usingPublic ? browserSessionPersistence : indexedDBLocalPersistence

    await setPersistence(auth, persistence)
    await signInWithCustomToken(auth, token)
  } catch (error) {
    return throwApiError(error)
  }
}

export const updateUserPassword = async (data: {
  oldPassword: string
  newPassword: string
  token?: string
}) => {
  try {
    return await apiFetch('/update-user-password', { method: 'POST', body: data })
  } catch (error) {
    return throwApiError(error)
  }
}

export const registerOrganization = async (data: {
  firstName: string
  lastName: string
  language: string
  email: string
  password: string
}) => {
  try {
    const { user }: { user: { id: string } } = await apiFetch('/register-organization', {
      method: 'POST',
      body: data,
    })

    const appStore = useAppStore()

    if (!user || !user.id) return

    console.log('[register] dispatch app/init...')
    await appStore.init()

    logEvent(analytics, 'sign_up')
  } catch (error) {
    return throwApiError(error)
  }
}

export const registerPartner = async (data: {
  firstName: string
  lastName: string
  language: string
  email: string
  password: string
}) => {
  try {
    const { user }: { user: { id: string } } = await apiFetch('/register-partner', {
      method: 'POST',
      body: data,
    })

    const appStore = useAppStore()

    if (!user || !user.id) return

    await appStore.init()

    logEvent(analytics, 'partner_sign_up')
  } catch (error) {
    return throwApiError(error)
  }
}

export const joinOrganization = async (data: {
  token: string
  firstName: string
  lastName: string
  language: string
  password: string
}) => {
  try {
    return await apiFetch('/join-organization', { method: 'POST', body: data })
  } catch (error) {
    return throwApiError(error)
  }
}

export const joinPartner = async (data: {
  token: string
  firstName: string
  lastName: string
  language: string
  password: string
}) => {
  try {
    return await apiFetch('/join-partner', { method: 'POST', body: data })
  } catch (error) {
    return throwApiError(error)
  }
}

export const verifyJoinOrganizationToken = async (data: { token: string }) => {
  try {
    const response = await apiFetch<VerifyJoinOrganizationTokenResponse>(
      '/verify-join-organization-token',
      { method: 'POST', body: data },
    )

    return validateObject(verifyJoinOrganizationTokenSchema, response)
  } catch (error) {
    return throwApiError(error)
  }
}

export const verifyJoinPartnerToken = async (data: { token: string }) => {
  try {
    const response = await apiFetch<VerifyJoinPartnerTokenResponse>('/verify-join-partner-token', {
      method: 'POST',
      body: data,
    })

    return validateObject(verifyJoinPartnerTokenSchema, response)
  } catch (error) {
    return throwApiError(error)
  }
}

export const verifyEmailChange = async (token: string) => {
  try {
    return await apiFetch('/verify-user-new-email', {
      method: 'POST',
      body: {
        token,
      },
    })
  } catch (error) {
    return throwApiError(error)
  }
}

export const verifyEmail = async (token: string) => {
  try {
    return await apiFetch('/verify-user-email', {
      method: 'POST',
      body: {
        token,
      },
    })
  } catch (error) {
    return throwApiError(error)
  }
}

export const reloadUser = () => {
  if (!auth.currentUser) return

  return auth.currentUser.reload()
}

export const registerTotpStep1LoggedOut = async (token: string) => {
  try {
    return await apiFetch<{ totpUri: string; recoveryKey: string; secret: string }>(
      '/setup-enforced-user-totp',
      {
        method: 'POST',
        body: { token },
      },
    )
  } catch (error) {
    return throwApiError(error)
  }
}

export const registerTotpStep2LoggedOut = async (token: string, errorToken: string) => {
  try {
    return await apiFetch('/verify-enforced-user-totp', {
      method: 'POST',
      body: { totp: { token }, token: errorToken },
    })
  } catch (error) {
    return throwApiError(error)
  }
}

export const registerTotpStep1 = async () => {
  try {
    return await apiFetch<{ totpUri: string; recoveryKey: string; secret: string }>(
      '/setup-user-totp',
      {
        method: 'POST',
      },
    )
  } catch (error) {
    return throwApiError(error)
  }
}

export const registerTotpStep2 = async (token: string) => {
  try {
    return await apiFetch('/verify-user-totp', { method: 'POST', body: { token } })
  } catch (error) {
    return throwApiError(error)
  }
}

export const deactivateTotpWithRecoveryCode = async (data: {
  email: string
  password: string
  usingPublic: boolean
  recovery: string
}) => {
  try {
    const { email, password, usingPublic, recovery } = data

    const persistence = usingPublic ? browserSessionPersistence : indexedDBLocalPersistence

    await setPersistence(auth, persistence)

    await apiFetch('/unset-user-totp-with-recovery-key', {
      method: 'POST',
      body: { email: email, recoveryKey: recovery },
    })

    await signInWithEmailAndPassword(auth, email, password)

    logEvent(analytics, 'login')
  } catch (error) {
    return throwApiError(error)
  }
}

export const unregisterTotp = async (token: string) => {
  try {
    return await apiFetch('/unset-user-totp', { method: 'POST', body: { token } })
  } catch (error) {
    return throwApiError(error)
  }
}

export const resetPassword = async (data: { token: string; password: string }) => {
  try {
    return await apiFetch('/reset-password', { method: 'POST', body: data })
  } catch (error) {
    return throwApiError(error)
  }
}

export const sendPasswordResetEmail = async (email: string) => {
  try {
    return await apiFetch('/forgotten-password', { method: 'POST', body: { email } })
  } catch (error) {
    return throwApiError(error)
  }
}
