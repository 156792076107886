import { z } from 'zod'

export const organizationSenderSchema = z.object({
  email: z.string(),
  name: z.string(),
})

export const organizationSchema = z.object({
  id: z.string(),
  name: z.string(),
  isTrainingAwarenessEnabled: z.boolean(),
  isTrainingAwarenessStarted: z.boolean(),
  sender: organizationSenderSchema.nullable(),
  createdAt: z.string().transform((value) => new Date(value)),
  logoUrl: z.string().nullable(),
})

export type Organization = z.infer<typeof organizationSchema>

export type OrganizationSender = z.infer<typeof organizationSenderSchema>

/**
 * Organization Statistics
 */

export const organizationStatisticsSchema = z.object({
  enrolledEmployeesRate: z.number(),
  successfulEmployeesRate: z.number(),
})

export type OrganizationStatistics = z.infer<typeof organizationStatisticsSchema>

export const readOrganizationStatisticsResponseSchema = z.object({
  statistics: organizationStatisticsSchema,
})

export type ReadOrganizationStatisticsResponse = z.infer<
  typeof readOrganizationStatisticsResponseSchema
>
