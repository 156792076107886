import { fetchWalkthrough } from '@/api/walkthrough'
import { Walkthrough } from '@/types/organization/walkthrough'
import { isEqual } from 'lodash-es'
import { defineStore } from 'pinia'

export const useWalkthroughStore = defineStore('walkthrough', {
  state: () => ({
    walkthrough: null as Walkthrough | null,
    isLoading: false,
  }),
  actions: {
    setWalkthrough(walkthrough: Walkthrough) {
      this.walkthrough = walkthrough
    },

    async setWalkthroughFromApi() {
      this.isLoading = true

      try {
        const walkthrough = await fetchWalkthrough()

        if (!isEqual(walkthrough, this.walkthrough)) {
          this.setWalkthrough(walkthrough)
        }
      } catch (error: any) {
        console.error('Error while fetching walkthrough')
        throw error
      } finally {
        this.isLoading = false
      }
    },
  },
})
