import { z } from 'zod'
import { TenantAccessType, OrganizationMemberPermission } from '.'

export const accessToSchema = z.object({
  id: z.string(),
  name: z.string(),
  type: z.nativeEnum(TenantAccessType),
  organizations: z
    .array(
      z.object({
        name: z.string(),
        id: z.string(),
        type: z.nativeEnum(TenantAccessType),
        permissionId: z.nativeEnum(OrganizationMemberPermission),
      }),
    )
    .optional(),
})

export type AccessTo = z.infer<typeof accessToSchema>
