import { z } from 'zod'

export enum SubscriptionPlan {
  TRIAL = 'TRIAL',
  ENTERPRISE = 'ENTERPRISE',
  ON_DEMAND = 'ON_DEMAND',
}

export const organizationSubscriptionTrialSchema = z.object({
  plan: z.literal(SubscriptionPlan.TRIAL),
  phishingQuota: z.object({
    free: z.number(),
    remaining: z.number(),
  }),
  expiresAt: z.string(),
})

export const organizationSubscriptionEnterpriseSchema = z.object({
  plan: z.literal(SubscriptionPlan.ENTERPRISE),
  seats: z.number(),
  belongsToPartnerId: z.string().optional().nullable(),
  endsAt: z.string(),
})

export const organizationSubscriptionOnDemandSchema = z.object({
  plan: z.literal(SubscriptionPlan.ON_DEMAND),
  belongsToPartnerId: z.string(),
})

export const organizationSubscriptionSchema = z.union([
  organizationSubscriptionTrialSchema,
  organizationSubscriptionEnterpriseSchema,
  organizationSubscriptionOnDemandSchema,
])

export type OrganizationSubscriptionTrial = z.infer<typeof organizationSubscriptionTrialSchema>

export type OrganizationSubscriptionEnterprise = z.infer<
  typeof organizationSubscriptionEnterpriseSchema
>

export type OrganizationSubscriptionOnDemand = z.infer<
  typeof organizationSubscriptionOnDemandSchema
>
