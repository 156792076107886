import { Action, Subject } from '@/types/acl'
import { RouteRecordRaw } from 'vue-router'

export default <RouteRecordRaw[]>[
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/pages/DashboardPage.vue'),
    meta: {
      subject: Subject.DASHBOARD,
      action: Action.READ,
    },
  },
]
