import { createI18n } from 'vue-i18n'
import enMessages from './locales/en.json'

export const i18n = createI18n({
  legacy: false,
  locale: 'en',
  messages: {
    en: enMessages,
  },
  fallbackLocale: 'en',
})

type AvailableLanguage = 'en' | 'fr'

/** @constant {string} DEFAULT_LANGUAGE */
const DEFAULT_LANGUAGE = 'en'
/** @constant {string} AVAILABLE_LANGUAGES */
const AVAILABLE_LANGUAGES: Set<AvailableLanguage> = new Set(['en', 'fr'])

export const getBrowserAvailableLanguage = (): AvailableLanguage => {
  const browserLanguage = navigator.language.split('-')[0]

  if (isAvailableLanguage(browserLanguage)) {
    return browserLanguage
  }

  return DEFAULT_LANGUAGE
}

export const isAvailableLanguage = (language: string): language is AvailableLanguage => {
  return AVAILABLE_LANGUAGES.has(language as AvailableLanguage)
}
