import { auth, db, functions } from '@/services/firebase'
import { useOrganizationStore } from '@/stores/organization'
import {
  EmailContentAppModel,
  EmailContentDbModel,
  Scenario,
  ScenarioContentAppModel,
  ScenarioContentDbModel,
  scenarioSchema,
} from '@/types/scenarios'
import { apiFetch } from '@/utils/api'
import { throwApiError, validateArray, validateObject } from '@/utils/api-data'
import { projectId } from '@/utils/env'
import { collection, doc, getCountFromServer, getDoc, query, where } from '@firebase/firestore'
import { httpsCallable } from '@firebase/functions'
import type {
  DocumentData,
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  SnapshotOptions,
} from 'firebase/firestore'
import { ofetch } from 'ofetch'
import { firestoreDefaultConverter } from 'vuefire'

const scenarioContentEmailConverter: FirestoreDataConverter<
  EmailContentAppModel,
  EmailContentDbModel
> = {
  toFirestore: (data) => {
    return data as EmailContentDbModel
  },
  fromFirestore: (snapshot, options) => {
    const data = snapshot.data(options)
    if (!data) throw new Error('Could not find data for email content in database.')

    return {
      sender: data.sender,
      subject: data.subject,
      data: data.data,
      attachment:
        data.attachments && data.attachments.length > 0
          ? {
              content: data.attachments[0].content,
              filename: data.attachments[0].filename.replace(/\.[^./]+$/, ''),
            }
          : null,
      ai: data.ai
        ? {
            jobTitle: data.ai.agent.jobTitle,
            signature: data.ai.agent.signature ?? null,
            tone: data.ai.agent.tone,
            prompt: data.ai.agent.prompt,
            isConversational: data.ai.agent.isConversational,
          }
        : null,
    }
  },
}

export const fetchScenario = async (scenarioId: string) => {
  try {
    const { scenario } = await apiFetch<{ scenario: Scenario }>(`/read-scenario/${scenarioId}`)

    return validateObject(scenarioSchema, scenario)
  } catch (error: any) {
    return throwApiError(error)
  }
}

export const fetchScenarios = async () => {
  try {
    const { scenarios } = await apiFetch<{ scenarios: Scenario[] }>('/list-scenarios')

    return validateArray(scenarioSchema, scenarios)
  } catch (error: any) {
    return throwApiError(error)
  }
}

export const toggleFavoriteScenario = async (id: string) => {
  try {
    await apiFetch('/toggle-favorite-scenarios', {
      method: 'POST',
      body: {
        scenarioIds: [id],
      },
    })
  } catch (error: any) {
    return throwApiError(error)
  }
}

export const fetchScenarioCount = async () => {
  const orgStore = useOrganizationStore()
  const orgId = orgStore.rootOrg.organization.id

  if (!orgId) throw new Error('Missing organization id')

  const builtinRef = collection(db, 'scenarios')

  const customRef = collection(db, 'organizations', orgId, 'scenarios')

  const customQuery = query(customRef, where('deleted', '==', false))

  const builtinQuery = query(builtinRef, where('deleted', '==', false))

  const [customSnap, builtinSnap] = await Promise.all([
    getCountFromServer(customQuery),
    getCountFromServer(builtinQuery),
  ])

  return customSnap.data().count + builtinSnap.data().count
}

export const previewLanding = async (scenarioId: string, isBuiltin: boolean = false) => {
  const orgStore = useOrganizationStore()
  const orgId = orgStore.rootOrg.organization.id

  if (!orgId) throw new Error('Missing organization id')

  const scenariosRef = isBuiltin
    ? doc(db, 'scenarios', scenarioId)
    : doc(db, 'organizations', orgId, 'scenarios', scenarioId)

  const landingRef = doc(scenariosRef, 'content', 'landing')

  const docSnap = await getDoc(landingRef)

  if (docSnap.exists()) {
    return docSnap.data() as ScenarioContentDbModel['landing']
  } else {
    throw new Error('No landing found for this scenario')
  }
}

export const previewEmail = async (scenarioId: string, isBuiltin: boolean = false) => {
  const orgStore = useOrganizationStore()
  const orgId = orgStore.rootOrg.organization.id

  if (!orgId) throw new Error('Missing organization id')

  const scenariosRef = isBuiltin
    ? doc(db, 'scenarios', scenarioId)
    : doc(db, 'organizations', orgId, 'scenarios', scenarioId).withConverter({
        toFirestore(scenario: any): DocumentData {
          return scenario.doc()
        },

        fromFirestore: (snapshot: QueryDocumentSnapshot, options: SnapshotOptions) => {
          const data = firestoreDefaultConverter.fromFirestore(snapshot, options)
          if (!data) return null

          data.metadata = snapshot.metadata

          return {
            name: data.name,
            desc: data.desc,
            builtin: data.builtin,
            deleted: data.deleted,
            createdAt: data.createdAt,
            categories: data.categories,
            flags: data.flags,
            lang: data.lang,
            mocking: data.mocking,
            rating: data.rating,
            type: data.type,
            subtype: data.subtype,
            thumbnail: data.thumbnail,
            updatedAt: data.updatedAt,
          }
        },
      })

  const emailRef = doc(scenariosRef, 'content', 'email')

  const docSnap = await getDoc(
    emailRef.withConverter<EmailContentAppModel, EmailContentDbModel>(
      scenarioContentEmailConverter,
    ),
  )

  if (docSnap.exists()) {
    return docSnap.data()
  } else {
    throw new Error('No email infos found for this scenario')
  }
}

export const duplicateScenario = (id: string) =>
  httpsCallable<{ id: string }, string>(functions, 'scenarios-duplicate')({ id })

export const deleteScenario = (scenarioId: string) =>
  httpsCallable(functions, 'scenarios-delete')(scenarioId)

export const createScenario = (data: {
  scenario: Partial<Scenario>
  content: ScenarioContentAppModel
}) => httpsCallable(functions, 'scenarios-create')(data)

export const updateScenario = (data: {
  scenario: Partial<Scenario>
  content: ScenarioContentAppModel
}) => httpsCallable(functions, 'scenarios-update')(data)

export async function* sendDryRunAIScenarioPretext(data: {
  jobTitle: string
  prompt: string
  tone: number
  language: string
  isConversational: boolean
}) {
  const userIdToken = await auth.currentUser?.getIdToken()

  try {
    const response = await ofetch(
      `https://europe-west3-${projectId}.cloudfunctions.net/sendDryRunAIScenarioPretext`,
      {
        method: 'POST',
        body: JSON.stringify(data),
        responseType: 'stream',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + userIdToken,
        },
      },
    )

    const reader = response.getReader()

    while (true) {
      const { done, value } = await reader.read()

      if (done) {
        break
      }

      yield new TextDecoder().decode(value)
    }
  } catch (error: any) {
    const reader = error.response.body.getReader()
    let receivedError = ''

    // eslint-disable-next-line no-constant-condition
    while (true) {
      const { done, value } = await reader.read()
      if (done) break
      receivedError += new TextDecoder().decode(value)
    }
    throw JSON.parse(receivedError)
  }
}

export async function* handleDryRunAIScenarioReply(data: { message: string }) {
  const userIdToken = await auth.currentUser?.getIdToken()

  try {
    const response = await ofetch(
      `https://europe-west3-${projectId}.cloudfunctions.net/handleDryRunAIScenarioReply`,
      {
        method: 'POST',
        body: JSON.stringify(data),
        responseType: 'stream',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + userIdToken,
        },
      },
    )

    const reader = response.getReader()
    const textDecoder = new TextDecoder()

    while (true) {
      const { done, value } = await reader.read()

      if (done) {
        break
      }

      yield textDecoder.decode(value)
    }
  } catch (error: any) {
    if (error.response && error.response.body) {
      const reader = error.response.body.getReader()
      const textDecoder = new TextDecoder()
      let receivedError = ''

      // eslint-disable-next-line no-constant-condition
      while (true) {
        const { done, value } = await reader.read()
        if (done) break
        receivedError += textDecoder.decode(value)
      }

      try {
        throw JSON.parse(receivedError)
      } catch {
        throw new Error(receivedError)
      }
    } else {
      throw new Error('An unknown error occurred')
    }
  }
}

export const suggestScenario = async (suggestion: string) => {
  try {
    await apiFetch('/suggest-scenario', {
      method: 'POST',
      body: {
        suggestion,
      },
    })
  } catch (error: any) {
    return throwApiError(error)
  }
}
