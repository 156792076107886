import { getAbility } from './rules'

let ability = getAbility(true)

if (import.meta.hot) {
  import.meta.hot?.on('vite:afterUpdate', () => {
    useAbility().update()
  })
}

export const useAbility = () => ({
  ability,
  update: () => (ability = getAbility()),
})
