import { Action, Subject } from '@/types/acl'
import { RouteRecordRaw } from 'vue-router'

export const vishingFeatureWallRoute: RouteRecordRaw = {
  path: '/vishing/overview',
  name: 'vishing',
  component: () => import('./VishingFeatureWall.vue'),
  meta: {
    action: Action.READ,
    subject: Subject.VISHING,
  },
}
