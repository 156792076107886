import { i18n } from '@/plugins/i18n'
import { auth } from '@/services/firebase'
import { useAppStore } from '@/stores/app'
import iziToast from 'izitoast'
import { $Fetch, FetchOptions, ofetch } from 'ofetch'
import { apiUrl } from './env'

const defaultOptions: FetchOptions = { baseURL: apiUrl }

let apiFetch: $Fetch

const refreshToken = () => auth.currentUser?.getIdToken(true)

const createFetchInstance = async () => {
  const options: FetchOptions = { ...defaultOptions }

  options.onRequest = async (context) => {
    const token = await auth.currentUser?.getIdToken()

    if (token) {
      context.options.headers = {
        ...context.options.headers,
        'Cache-Control': 'default',
        Authorization: `Bearer ${token}`,
      }
    }
  }

  /**
   * @todo refactor
   * This is a temporary solution to handle 401 responses.
   * We should handle it at the component level.
   */
  options.onResponse = async (context) => {
    if (context.response.status === 401) {
      // 401 for log-in-user is expected behavior for MFA & bad credentials
      const req = context.request as string

      if (req.includes('log-in-user') || req.includes('mfa')) return

      /**
       * Handled at the component level
       * @see AccountSecurityRemoveModal
       */
      if (req.includes('unset-user-totp')) return

      /**
       * Handled at the routes level
       * Should redirect to the 404 page
       * @see /modules/Whitelist/routes.ts
       */
      if (
        req.includes('confirm-whitelisting') ||
        req.includes('retrieve-whitelisting-instructions') ||
        /** Avoid redirecting to login page: not critical */
        req.includes('set-email-provider')
      ) {
        return
      }

      iziToast.error({
        message: i18n.global.t('errors.unauthorized'),
        timeout: 7000,
      })

      const appStore = useAppStore()

      appStore.destroy()
    }
  }

  apiFetch = ofetch.create(options)
}

createFetchInstance()

export { apiFetch, createFetchInstance, refreshToken }
