import { Action, Subject } from '@/types/acl'
import { RouteRecordRaw } from 'vue-router'
import { organizationRepository } from '../../infra/adapters/organization.repository'

export const domainMonitoringRoute: RouteRecordRaw = {
  /**
   * Parent path: /domain-monitoring
   * Defined in src/modules/CyberThreatIntelligence/app/routes.ts
   */
  path: '',
  name: 'domain-monitoring-list',
  component: () => import('./DomainMonitoringPage.vue'),
  meta: {
    action: Action.READ,
    subject: Subject.CYBER_THREAT_INTELLIGENCE,
  },
  beforeEnter: async (to, from, next) => {
    const { isCyberThreatIntelligenceEnabled } =
      await organizationRepository.isCyberThreatIntelligenceEnabled()

    isCyberThreatIntelligenceEnabled ? next() : next({ name: 'feature-wall-domain-monitoring' })
  },
}
