import { RouteRecordRaw } from 'vue-router'
import { organizationRepository } from '../infra/adapters/organization.repository'
import { trainingAwarenessFeatureWallRoute } from './views/featureWall/route'
import { trainingAwarenessListLessonsRoute } from './views/listLessons/route'

export const trainingAwarenessRoutes: RouteRecordRaw[] = [
  {
    name: 'training-awareness',
    path: '/training-awareness',
    redirect: { name: trainingAwarenessListLessonsRoute.name },
    beforeEnter: async (to, from, next) => {
      try {
        const organization = await organizationRepository.readOrganization()

        if (organization.isTrainingAwarenessEnabled) {
          if (to.name === trainingAwarenessFeatureWallRoute.name) {
            next({ name: trainingAwarenessListLessonsRoute.name })
          } else {
            next() // Allow navigation
          }
        } else {
          if (to.name !== trainingAwarenessFeatureWallRoute.name) {
            next({ name: trainingAwarenessFeatureWallRoute.name })
          } else {
            next() // Already on feature-wall-training-awareness, so allow navigation
          }
        }

        to.meta.organization = organization
        next()
      } catch (error: any) {
        if (error.status === 404) {
          if (to.name !== trainingAwarenessFeatureWallRoute.name) {
            next({ name: trainingAwarenessFeatureWallRoute.name })
          } else {
            next() // Already on feature-wall-training-awareness, so allow navigation
          }
        }
        throw error
      }
    },
    children: [trainingAwarenessListLessonsRoute, trainingAwarenessFeatureWallRoute],
  },
]
