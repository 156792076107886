import { Action, Subject } from '@/types/acl'
import { RouteRecordRaw } from 'vue-router'
import { useScenarioForms } from './ScenarioStepper/useScenarioForms'

export default <RouteRecordRaw[]>[
  {
    path: '/scenarios',
    name: 'scenario',
    redirect: {
      name: 'scenario-list',
    },
    meta: {
      subject: Subject.SCENARIO,
      action: Action.LIST,
    },
    children: [
      {
        path: '',
        component: () => import('@/pages/ScenarioListPage.vue'),
        name: 'scenario-list',
        meta: {
          subject: Subject.SCENARIO,
          action: Action.LIST,
        },
      },
      {
        path: 'create',
        name: 'scenario-create',
        component: () => import('@/pages/ScenarioCreatePage.vue'),
        meta: {
          subject: Subject.SCENARIO,
          action: Action.CREATE,
        },
      },
      {
        path: 'edit/:scenarioId',
        name: 'scenario-edit',
        component: () => import('@/pages/ScenarioEditPage.vue'),
        async beforeEnter(to) {
          const id = to.params.scenarioId as string
          const { fetch } = useScenarioForms()

          if (id) {
            await fetch(id)
          }
        },
        meta: {
          subject: Subject.SCENARIO,
          action: Action.UPDATE,
        },
      },
    ],
  },
]
