import { createVuetify } from 'vuetify'
import defaults from './defaults'
import theme from './theme'

// Styles
import '@mdi/font/css/materialdesignicons.css'
import '@core/scss/template/libs/vuetify/index.scss'
import 'vuetify/styles'

export default createVuetify({
  defaults,
  theme,
})
