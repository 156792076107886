<script lang="ts" setup>
import { useSkins } from '@core/composable/useSkins'

const DefaultLayoutWithVerticalNav = defineAsyncComponent(
  () => import('./components/DefaultLayoutWithVerticalNav.vue'),
)

const { layoutAttrs, injectSkinClasses } = useSkins()

injectSkinClasses()
</script>

<template>
  <DefaultLayoutWithVerticalNav v-bind="layoutAttrs" />
</template>

<style src="@layouts/styles/_default-layout.scss" lang="scss" />
