import { Action, Subject } from '@/types/acl'
import { RouteRecordRaw } from 'vue-router'

export default <RouteRecordRaw[]>[
  {
    path: '/groups',
    name: 'group',
    redirect: {
      name: 'group-list',
    },
    meta: {
      subject: Subject.GROUP,
      action: Action.LIST,
    },
    children: [
      {
        path: '',
        component: () => import('@/pages/GroupListPage.vue'),
        name: 'group-list',
        meta: {
          subject: Subject.GROUP,
          action: Action.LIST,
        },
      },
      {
        path: ':groupId',
        name: 'standard-group-view',
        component: () => import('@/pages/GroupViewPage.vue'),
        props: {
          isDynamic: false,
        },
        meta: {
          subject: Subject.GROUP,
          action: Action.READ,
          navActiveLink: 'group-list',
        },
      },
    ],
  },
  {
    path: '/dynamic-groups/:groupId',
    name: 'dynamic-group-view',
    component: () => import('@/pages/GroupViewPage.vue'),
    props: {
      isDynamic: true,
    },
    meta: {
      subject: Subject.GROUP,
      action: Action.READ,
      navActiveLink: 'group-list',
    },
  },
]
