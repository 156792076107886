import { Action, Subject } from '@/types/acl'
import { RouteRecordRaw } from 'vue-router'

export const smishingFeatureWallRoute: RouteRecordRaw = {
  path: '/smishing/overview',
  name: 'smishing',
  component: () => import('./SmishingFeatureWall.vue'),
  meta: {
    action: Action.READ,
    subject: Subject.SMISHING,
  },
}
