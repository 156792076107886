import { z } from 'zod'

export const walkthroughSchema = z
  .object({
    // Common
    isEnrichmentVerified: z.boolean(),
    isFirstCampaignSent: z.boolean(),

    // Google Walkthrough
    isGoogleIdentityDirectoryEnabled: z.boolean(),
    isGoogleEmailDeliveryEnabled: z.boolean(),

    // Manual Walkthrough
    isDomainVerified: z.boolean(),
    isEmployeeAdded: z.boolean(),
    isWhitelistingConfirmed: z.boolean(),

    // Microsoft Walkthrough
    isMicrosoftIdentityDirectoryEnabled: z.boolean(),
    isMicrosoftEmailDeliveryEnabled: z.boolean(),
  })
  .nullable()

export type Walkthrough = z.infer<typeof walkthroughSchema>
