import { FirebaseDate } from './firebase'
import { Group } from './organization/group'

export enum CampaignGoal {
  TRAINING = 'TRAINING',
  ASSESSMENT = 'ASSESSMENT',
}

export enum CampaignUsecase {
  CLICK = 'CLICK',
  CREDENTIAL_HARVESTING = 'CREDENTIAL_HARVESTING',
}

export enum CampaignType {
  EMAIL = 'EMAIL',
}

export enum CampaignStatus {
  PROCESSING = 'PROCESSING',
  RUNNING = 'RUNNING',
  COMPLETED = 'COMPLETED',
  // Unused for now
  DRAFT = 'DRAFT',
  PENDING = 'PENDING',
  ABORTED = 'ABORTED',
}

export type CampaignId = string

export type Campaign = {
  id: CampaignId
  createdAt: FirebaseDate
  completedAt: FirebaseDate
  abortedAt: FirebaseDate
  createdBy: string
  durations: Record<string, string>
  goal: CampaignGoal
  name: string
  privateDataRef: {
    path?: string
    targetPool: Record<string, any>
    count: {
      email?: {
        bounced?: number
        opened?: number
        delivered?: number
      }
      landing?: {
        clicked?: number
        compromised?: number
      }
      campaign?: {
        targets?: number
        reported?: number
      }
    }
  } | null
  origin?: string
  processingAt: FirebaseDate
  recurring: boolean
  runningAt: FirebaseDate
  scenarios: string[]
  schedule: {
    constraints: {
      begin: string
      finish: string
    }
    dates: FirebaseDate[]
    end: FirebaseDate
    start: FirebaseDate
    tz: string
  }
  score: number
  status: CampaignStatus
  type: CampaignType
  usecase: CampaignUsecase
  updatedAt: FirebaseDate
}

export type Target = {
  pid: string
  createdAt: FirebaseDate
  email: string
  employeeId: string
  employeeRef: string
  firstName: string
  lastName: string
  groups: Group[]
  lang: string
  phone: string
  scenario: string
  score: number
  timezone: string
  passwordDetails?: {
    isCopyPasted: boolean
    strength: {
      crackTimes: string
      score: number
    }
  }
  stats: {
    campaign?: {
      reported: boolean
    }
    email: {
      bounced: boolean
      opened: boolean
      delivered: boolean
      replied: boolean
    }
    landing: {
      compromised: boolean
      clicked: boolean
    }
  }
}

export type TargetEvent = {
  id: string
  at: FirebaseDate
  employee: string
  payload: any
  scope: string
  type: string
}

export enum LogType {
  CLICKED = 'clicked',
  COMPROMISED = 'compromised',
  DELIVERED = 'delivered',
  REPORTED = 'reported',
  OPENED = 'opened',
  QUEUED = 'queued',
  BOUNCED = 'bounced',
  REPLIED = 'replied',
}

export type Log = {
  metadata: {
    at: FirebaseDate
    target: {
      firstName: string
      lastName: string
    }
    type: LogType
  }
  id: string
  at: FirebaseDate
}
