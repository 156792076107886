import { iframeResizer } from 'iframe-resizer'
import type { Directive } from 'vue'

export const resizerDirective = (): Directive => {
  return {
    mounted: (el: any, { value = {} }) => {
      el.addEventListener('load', () => iframeResizer(value, el))
    },
    beforeMount: (el: any) => {
      if (el.iFrameResizer) {
        el.iFrameResizer.removeListeners()
      }
    },
  }
}
