// App
import type { App } from 'vue'
import { createApp } from 'vue'
import AppComponent from '@/App.vue'

// Plugin
import layoutsPlugin from '@/plugins/layouts'
import vuetify from '@/plugins/vuetify'
import CKEditor from '@ckeditor/ckeditor5-vue'
import { createPinia } from 'pinia'
import { i18n } from '@/plugins/i18n'
import { initSentry } from './plugins/sentry'

// Directives
import { resizerDirective } from '@/directives/resizer.directive'

// Lib
import '@/libs/webfontloader'
import '@/libs/izitoast'
import '@/libs/crisp'
import '@/libs/hotjar'

// Styles
import 'izitoast/dist/css/iziToast.min.css'
import '@core/scss/template/index.scss'
import '@styles/_styles.scss'

import { logEvent, setUserId } from 'firebase/analytics'
import { analytics, auth } from './services/firebase'
import { useObservers } from './composables/useObservers'
import { createFetchInstance } from './utils/api'

import { useAppStore } from './stores/app'
import { useMyselfStore } from './stores/myself'
import { router } from './router'
import { Settings } from 'luxon'

let hasInitiated = false

const app: App = createApp(AppComponent)

const store = createPinia()

app.use(store)

if (import.meta.env.DEV) {
  app.config.performance = true
}

// Luxon locale
Settings.defaultLocale = 'en'

if (app.config) {
  app.config.errorHandler = (err, instance, info) => {
    console.log('Vue error:', err, instance, info)
  }
}

const initApp = () => {
  if (hasInitiated) return

  initSentry(app)

  app.use(CKEditor).use(vuetify).use(router).use(layoutsPlugin).use(i18n)

  app.directive('resizer', resizerDirective())

  // Mount vue app
  app.mount('#app')

  hasInitiated = true
}

// React to firebase auth change
auth.onAuthStateChanged(async (user) => {
  const appStore = useAppStore()
  const myselfStore = useMyselfStore()

  if (user) {
    await createFetchInstance()

    console.log('[onAuthStateChanged] User is signed-in...')

    const { observesWebClientReleaseVersion } = useObservers()

    observesWebClientReleaseVersion()

    if (appStore.hasLoggedIn) {
      logEvent(analytics, 'login')
      setUserId(analytics, user.uid)

      appStore.hasLoggedIn = false

      console.log('[Router] Push to "dispatcher"')
      router.push({ name: 'dispatcher' })
    } else {
      console.log('[onAuthStateChanged] Start init...')
      try {
        await appStore.init()
        console.log('[onAuthStateChanged] Complete init...')
      } catch (error) {
        console.log('[onAuthStateChanged] Error when initialize app', error)
        console.log('[Router] Push to "error"')
        console.error(error)

        // Redirect to error page
        router.push({ name: 'error' })
      }
    }
  } else if (myselfStore.myself?.id) {
    console.log('[onAuthStateChanged] User is signed-out...')
    await appStore.destroy()
  }

  initApp()
})
