import { z } from 'zod'
import { PartnerMemberPermission } from '../user'

const partnerPersonSchema = {
  permissionId: z.nativeEnum(PartnerMemberPermission),
  assignedToAllOrganizations: z.boolean(),
  assignedToOrganizationIds: z.array(z.string()),
  firstName: z.string(),
  lastName: z.string(),
  email: z.string(),
}

export const partnerTeamMemberSchema = z.object({
  ...partnerPersonSchema,
  id: z.string(),
  provider: z.enum(['password', 'google', 'microsoft']),
  isMfaEnrolled: z.boolean(),
})

export const partnerTeamInviteeSchema = z.object({
  ...partnerPersonSchema,
  id: z.null().default(null),
  provider: z.null().default(null),
  isMfaEnrolled: z.null().default(null),
})

export const verifyJoinPartnerTokenSchema = z.object({
  invitee: partnerTeamInviteeSchema.pick({
    firstName: true,
    lastName: true,
    email: true,
  }),
  partner: z.object({
    name: z.string(),
    isSsoEnforced: z.boolean(),
  }),
})

export type PartnerTeamPerson = z.infer<
  typeof partnerTeamMemberSchema | typeof partnerTeamInviteeSchema
>
export type PartnerTeamMember = z.infer<typeof partnerTeamMemberSchema>
export type PartnerTeamInvitee = z.infer<typeof partnerTeamInviteeSchema>
export type VerifyJoinPartnerTokenResponse = z.infer<typeof verifyJoinPartnerTokenSchema>
