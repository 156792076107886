import { Action, Subject } from '@/types/acl'
import { RouteRecordRaw } from 'vue-router'

export const trainingAwarenessFeatureWallRoute: RouteRecordRaw = {
  /**
   * Parent path: /courses
   * Defined in src/modules/TrainingAwareness/app/routes.ts
   */
  path: 'overview',
  name: 'feature-wall-training-awareness',
  component: () => import('./TrainingAwarenessFeatureWall.vue'),
  meta: {
    action: Action.READ,
    subject: Subject.TRAINING_AWARENESS,
  },
}
