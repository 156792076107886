import iziToast from 'izitoast'
import type { ZodSchema, ZodTypeAny, z } from 'zod'
import * as Sentry from '@sentry/browser'

export class MalformatedSchemaError extends Error {
  constructor(message: string) {
    super(`Malformated schema: ${message}`)
  }
}

export const throwApiError = (error: any) => {
  // ignore network error
  if (error.name !== 'FetchError') {
    Sentry.captureException(error)
  }

  if (error.data?.statusCode === 500 || error.statusCode === 500) {
    iziToast.error({
      title: `${error.message}`,
      message: 'Please try again later or contact our support.',
      icon: 'mdi mdi-alert-circle-outline',
      timeout: 5000,
    })
  } else if (error instanceof MalformatedSchemaError) {
    console.error(error.message)
  }

  if (error.data) {
    const { message, statusCode, ...rest } = error.data

    throw {
      message,
      status: statusCode,
      ...rest,
    }
  } else {
    throw error
  }
}

export const validateObject = <S extends ZodTypeAny>(schema: S, item: unknown): z.infer<S> => {
  const result = schema.safeParse(item)

  if (result.success) {
    return result.data
  }

  throw new MalformatedSchemaError(result.error.message)
}

export const validateArray = <T>(schema: ZodSchema, items: T[]): T[] =>
  items.filter((item) => {
    try {
      validateObject(schema, item)

      return true
    } catch (error: any) {
      console.log(error)

      return false
    }
  })
