import type { RouteRecordRaw } from 'vue-router'
import { useCampaignBinding } from './CampaignView/useCampaignBinding'
import { Action, Subject } from '@/types/acl'

export default <RouteRecordRaw[]>[
  {
    path: '/campaigns',
    name: 'campaign',
    redirect: {
      name: 'campaign-list',
    },
    meta: {
      action: Action.LIST,
      subject: Subject.CAMPAIGN,
    },
    children: [
      {
        path: '',
        name: 'campaign-list',
        component: () => import('@/pages/CampaignListPage.vue'),
        meta: {
          subject: Subject.CAMPAIGN,
          action: Action.LIST,
        },
      },
      {
        path: 'create',
        name: 'campaign-create',
        component: () => import('@/pages/CampaignCreatePage.vue'),
        meta: {
          subject: Subject.CAMPAIGN,
          action: Action.CREATE,
        },
      },
      {
        path: ':campaignId',
        name: 'campaign-view',
        component: () => import('@/pages/CampaignViewPage.vue'),
        meta: {
          subject: Subject.CAMPAIGN,
          action: Action.READ,
        },
        beforeEnter(to) {
          const { bindCampaign, bindTargets, bindCampaignLogs } = useCampaignBinding()

          bindCampaign(to.params.campaignId as string)
          bindTargets(to.params.campaignId as string)
          bindCampaignLogs(to.params.campaignId as string)
        },
      },
    ],
  },
]
