import { themeConfig } from '@themeConfig'

const theme: any = {
  defaultTheme:
    localStorage.getItem(`${themeConfig.app.title}-theme`) || themeConfig.app.theme.value,
  themes: {
    light: {
      dark: false,
      colors: {
        white: '#fff',
        black: '#000',
        primary: '#7367F0',
        'on-primary': '#fff',
        'primary-light': '#7e74e8',
        secondary: '#82868b',
        'secondary-light': '#d0cfd2',
        'on-secondary': '#fff',
        tertiary: '#6e6b7b',
        light: '#F2F3F7',
        success: '#00C853',
        'success-light': '#6deda7',
        'on-success': '#fff',
        info: '#4356E9',
        'on-info': '#fff',
        warning: '#FF9F43',
        'on-warning': '#fff',
        'warning-light': '#FFC690',
        error: '#ff0033',
        'on-error': '#fff',
        red: '#e32426',
        'error-light': '#fa6666',
        'background-dark': '#f6f6f6',
        'training-background': '#f8f7fa',
        'training-text': '#5e5871',
        background: '#F8F7FA',
        badge: '#82868b',
        mark: '#fff3b3',
        'on-background': '#33303C',
        'on-surface': '#33303C',
        'grey-50': '#FAFAFA',
        'grey-100': '#F5F5F5',
        'grey-200': '#EEEEEE',
        'grey-300': '#E0E0E0',
        'grey-400': '#BDBDBD',
        'grey-500': '#9E9E9E',
        'grey-600': '#757575',
        'grey-700': '#616161',
        'grey-800': '#424242',
        'grey-900': '#212121',
        'perfect-scrollbar-thumb': '#DBDADE',
      },

      variables: {
        'medium-emphasis-opacity': 0.9,
        'high-emphasis-opacity': 1,
        'disabled-opacity': 0.7,

        'code-color': '#d400ff',
        'overlay-scrim-background': '#33303C',
        'tooltip-background': '#212121',
        'overlay-scrim-opacity': 0.32,
        'hover-opacity': 0.04,
        'focus-opacity': 0.12,
        'selected-opacity': 0.06,
        'activated-opacity': 0.16,
        'pressed-opacity': 0.14,
        'dragged-opacity': 0.1,
        'border-color': '#2F2B3D',
        'border-opacity': 0.16,
        'switch-opacity': 0.7,
        'switch-disabled-track-opacity': 0.3,
        'switch-disabled-thumb-opacity': 0.4,
        'switch-checked-disabled-opacity': 0.3,

        // Shadows
        'shadow-key-umbra-color': '#2F2B3D',
      },
    },
    dark: {
      dark: true,
      colors: {
        white: '#fff',
        black: '#000',
        primary: '#7367F0',
        'on-primary': '#fff',
        'primary-light': '#aea8ed',
        secondary: '#82868b',
        'secondary-light': '#5a5c70',
        'on-secondary': '#fff',
        tertiary: '#D0D2D6',
        light: '#343d55',
        success: '#28C76F',
        'success-light': '#c3efd7',
        'on-success': '#fff',
        info: '#6877ed',
        'on-info': '#fff',
        warning: '#FF9F43',
        'on-warning': '#fff',
        'warning-light': '#FFC690',
        error: '#EA5455',
        'on-error': '#fff',
        'error-light': '#fa6666',
        red: '#e32426',
        background: '#25293C',
        'on-background': '#E4E6F4',
        surface: '#2F3349',
        'training-background': '#f8f7fa',
        'training-text': '#5e5871',
        badge: '#161d31',
        mark: '#4356E9',
        'background-dark': '#1c2338',
        'on-surface': '#E4E6F4',
        'on-surface-light': '#B6B8C2',
        'grey-50': '#26293A',
        'grey-100': '#2F3349',
        'grey-200': '#26293A',
        'grey-300': '#4A5072',
        'grey-400': '#5E6692',
        'grey-500': '#7983BB',
        'grey-600': '#AAB3DE',
        'grey-700': '#B6BEE3',
        'grey-800': '#CFD3EC',
        'grey-900': '#E7E9F6',
        'perfect-scrollbar-thumb': '#4A5072',
      },
      variables: {
        // 'border-color': '#CFD3EC',
        'medium-emphasis-opacity': 0.9,
        'high-emphasis-opacity': 1,
        'disabled-opacity': 0.7,

        'code-color': '#d400ff',
        'tooltip-background': '#212121',
        'overlay-scrim-background': '#E4E6F4',
        'overlay-scrim-opacity': 0.32,
        'hover-opacity': 0.04,
        'focus-opacity': 0.12,
        'selected-opacity': 0.06,
        'activated-opacity': 0.16,
        'pressed-opacity': 0.14,
        'dragged-opacity': 0.1,
        'border-color': '#D0D4F1',
        'border-opacity': 0.16,
        'switch-opacity': 0.7,
        'switch-disabled-track-opacity': 0.4,
        'switch-disabled-thumb-opacity': 0.8,
        'switch-checked-disabled-opacity': 0.3,

        // Shadows
        'shadow-key-umbra-color': '#0F1422',
      },
    },
  },
}

export default theme
