import { Walkthrough, walkthroughSchema } from '@/types/organization/walkthrough'
import { apiFetch } from '@/utils/api'
import { throwApiError, validateObject } from '@/utils/api-data'

export const fetchWalkthrough = async () => {
  try {
    const res = await apiFetch<{ walkthrough: Walkthrough }>('/walkthrough', { method: 'POST' })

    return validateObject(walkthroughSchema, res?.walkthrough)
  } catch (error) {
    return throwApiError(error)
  }
}
