import { fetchWhitelistingInstructions } from '@/api/whitelisting'
import { WhitelistingInstructions } from '@/types/whitelisting'
import { defineStore } from 'pinia'

export const useWhitelistingStore = defineStore('whitelisting', {
  state: () => ({
    instructions: undefined as WhitelistingInstructions | undefined,
    isLoading: false,
  }),
  actions: {
    setInstructions(instructions: WhitelistingInstructions) {
      this.instructions = instructions
    },

    async setInstructionsFromApi(token: string) {
      this.isLoading = true

      try {
        const instructions = await fetchWhitelistingInstructions(token)

        this.setInstructions(instructions)
      } catch (error: any) {
        console.error('Error while fetching whitelisting instructions')
        throw error
      } finally {
        this.isLoading = false
      }
    },
  },
})
