import iziToast from 'izitoast'
import { RouteRecordRaw } from 'vue-router'
import { i18n } from '@/plugins/i18n'
import { useWhitelistingStore } from '@/stores/whitelisting'
import { confirmWhitelisting } from '@/api/whitelisting'

export default <RouteRecordRaw[]>[
  {
    path: '/whitelisting',
    name: 'whitelisting',
    component: () => import('@/pages/WhitelistInstructionsPage.vue'),
    meta: {
      layout: 'whitelisting',
    },
    async beforeEnter(to, from, next) {
      if (to.query.token) {
        const instructionsStore = useWhitelistingStore()

        try {
          await instructionsStore.setInstructionsFromApi(to.query.token as string)
          next()
        } catch (error: any) {
          if (error.status === 401) {
            iziToast.error({ message: i18n.global.t('errors.instruction-token-not-found') })
          } else {
            iziToast.error({ message: error.message })
          }

          next({ name: '404' })
        }
      } else {
        next({ name: '404' })
      }
    },
  },
  {
    path: '/confirm-whitelisting',
    name: 'confirm-whitelisting',
    meta: {
      layout: 'whitelisting',
    },
    component: () => import('@/pages/WhitelistConfirmationPage.vue'),
    async beforeEnter(to, _, next) {
      if (to.query.token) {
        try {
          await confirmWhitelisting(to.query.token as string)

          iziToast.success({ message: i18n.global.t('whitelisting-confirmed'), timeout: 5000 })
          next()
        } catch (error: any) {
          if (error.status === 401) {
            iziToast.error({ message: i18n.global.t('errors.instruction-token-not-found') })
          } else {
            iziToast.error({ message: error.message })
          }

          next({ name: '404' })
        }
      } else {
        next({ name: '404' })
      }
    },
  },
]
