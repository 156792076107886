import { Action, Subject } from '@/types/acl'
import { RouteRecordRaw } from 'vue-router'

export default <RouteRecordRaw[]>[
  {
    path: '/account',
    redirect: { name: 'account-general' },
    name: 'account',
    component: () => import('@/pages/AccountPage.vue'),
    meta: {
      action: Action.READ,
      subject: Subject.ACCOUNT,
    },
    children: [
      {
        path: 'general',
        name: 'account-general',
        component: () => import('./components/AccountGeneral'),
        meta: {
          subject: Subject.ACCOUNT_GENERAL,
          action: Action.READ,
        },
      },
      {
        path: 'security',
        name: 'account-security',
        component: () => import('./components/AccountSecurity'),
        meta: {
          subject: Subject.ACCOUNT_SECURITY,
          action: Action.READ,
        },
      },
    ],
  },
]
