import { WhitelistingProvider } from '@/modules/Whitelist/WhitelistingProvider'
import { functions } from '@/services/firebase'
import { WhitelistingInstructions, whitelistingInstructionsSchema } from '@/types/whitelisting'
import { apiFetch } from '@/utils/api'
import { throwApiError, validateObject } from '@/utils/api-data'
import { httpsCallable } from 'firebase/functions'

export const fetchWhitelistingInstructions = async (token: string) => {
  try {
    const { instructions } = await apiFetch<{ instructions: WhitelistingInstructions }>(
      `/retrieve-whitelisting-instructions?token=${token}`,
    )

    return validateObject(whitelistingInstructionsSchema, instructions)
  } catch (error: any) {
    return throwApiError(error)
  }
}

export const sendTestEmail = (email: string, token: string) =>
  httpsCallable(functions, 'whitelisting-check')({ email, token })

export const shareWhitelistingInstructions = async (email: string, message: string) => {
  try {
    await apiFetch(`/share-whitelisting-instructions`, {
      method: 'POST',
      body: {
        email,
        message,
      },
    })
  } catch (error: any) {
    return throwApiError(error)
  }
}

export const setEmailProvider = async (token: string, provider: WhitelistingProvider) => {
  try {
    await apiFetch(`/set-email-provider`, {
      method: 'POST',
      body: {
        token,
        emailProvider: provider,
      },
    })
  } catch (error: any) {
    return throwApiError(error)
  }
}

export const fetchInstructionsUrl = async () => {
  try {
    const { url } = await apiFetch<{ url: string }>(`/generate-whitelisting-instructions-url`)

    return url
  } catch (error: any) {
    return throwApiError(error)
  }
}

export const confirmWhitelisting = async (token: string) => {
  try {
    await apiFetch<{ url: string }>(`/confirm-whitelisting`, {
      method: 'POST',
      body: {
        token,
      },
    })
  } catch (error: any) {
    return throwApiError(error)
  }
}
