{
  "verified": "Your email is now verified",
  "copied": "Copied to clipboard",
  "rating": {
    "difficult": "Difficult",
    "very-difficult": "Very difficult",
    "easy": "Easy",
    "normal": "Normal",
    "very-easy": "Very easy"
  },
  "tour": {
    "next": "Next step",
    "back": "Back",
    "end": "Done",
    "walkthrough": {
      "text": "Complete all these steps to get started and run your first phishing simulation campaign.",
      "title": "Quick-start Guide"
    },
    "company": {
      "text": "Easily manage your employees and groups directory. Groups help making phishing simulation campaigns more relevant.",
      "title": "Organization Directory"
    },
    "scenarios": {
      "text": "Discover our real-life simulated scenarios, duplicate and create yours without limitation.",
      "title": "Scenarios"
    },
    "phishing": {
      "text": "Create and manage your phishing simulation campaigns and recurring campaigns in a few clicks.",
      "title": "Phishing"
    },
    "contact": {
      "text": "Any question? Our support team is available via live chat.",
      "title": "Support"
    }
  },
  "signup-tos": "By signing up, you agree with Arsen's <a target='_blank' href='https://arsen.co/en/terms'>Terms & Conditions</a> and <a target='_blank' href='https://arsen.co/en/privacy-policy'>Privacy Policy</a>.",
  "entropy": {
    "very-weak": "very weak",
    "weak": "weak",
    "normal": "normal",
    "strong": "strong",
    "very-strong": "very strong"
  },
  "errors": {
    "connection": "An issue occurred when connecting to our server: either you are not connected to Internet or you have a firewall issue. Please contact your IT department or VPN provider and ask them for guidance to open TCP port 443 and safelist the following domains: 'https://app.arsen.co/' and 'https://firestore.googleapis.com/' {message}",
    "unauthorized": "Your session or token is expired or invalid. Please try again.",
    "invitation": "This invitation has already been enabled or was revoked by your administrator.",
    "login": "There was an error while fetching your account, please contact our support team.",
    "instruction-token-not-found": "Link has expired or is invalid.",
    "forbidden": "You are not allowed to access this page.",
    "sso": {
      "can_only_register_with_business_email": "You can only register with a business email address.",
      "user_not_found": "There is no account associated with this email address. Please contact your account manager.",
      "user_already_exists": "Your already have an account. Please use the login page to sign-in.",
      "action_not_supported": "We couldn't sign you in. Please try again or contact our customer support.",
      "unknown_error": "An error occurred while retrieving your account. Please contact our customer support.",
      "domain_already_in_use": "You are not able to create an account with this email. Please contact our customer support.",
      "organization_invitation_not_found_error": "No invitation was found using this email account. Please contact your manager for further assistance.",
      "partner_invitation_not_found_error": "No invitation was found using this email account. Please contact your manager for further assistance."
    }
  },
  "integrations": {
    "directory-sync": {
      "error": {
        "unauthorized_client": "We are not able to sync your users, please ensure you have followed the above steps correctly and you are signed-in to Arsen using your Google Workspace admin account.",
        "invalid_client": "You are signed-in with an email lacking Admin privileges for Google Workspace. Please either sign-in to Arsen using a Google Workspace admin account or invite a Google Workspace administrator to join your team on Arsen.",
        "invalid_grant": "We are not able to sync your users, please ensure you have followed the above steps correctly and you are signed-in to Arsen using your Google Workspace admin account."
      }
    }
  },
  "whitelisting-confirmed": "Whitelisting confirmed, you can go back to the instructions",
  "frequencies": {
    "EVERY_WEEK": "Every week",
    "EVERY_YEAR": "Every year",
    "EVERY_MONTH": "Every month",
    "EVERY_2_WEEKS": "Every 2 weeks",
    "EVERY_3_WEEKS": "Every 3 weeks",
    "EVERY_2_MONTHS": "Every 2 months",
    "EVERY_3_MONTHS": "Every 3 months",
    "EVERY_4_MONTHS": "Every 4 months",
    "EVERY_5_MONTHS": "Every 5 months",
    "EVERY_6_MONTHS": "Every 6 months",
    "EVERY_7_MONTHS": "Every 7 months",
    "EVERY_8_MONTHS": "Every 8 months",
    "EVERY_9_MONTHS": "Every 9 months",
    "EVERY_10_MONTHS": "Every 10 months",
    "EVERY_11_MONTHS": "Every 11 months"
  },
  "sso": {
    "microsoft": "Sign in with Microsoft",
    "google": "Sign in with Google"
  }
}
