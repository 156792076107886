import { z } from 'zod'

// Schemas

export const partnerBillingApiSchema = z.object({
  email: z.string(),
})

export const partnerBillingInvoiceApiSchema = z.object({
  issuedAt: z.string(),
  currency: z.string(),
  subtotalExcludingTax: z.number(),
  total: z.number(),
  quota: z.number(),
  pdfUrl: z.string().nullable(),
})

// Endpoints

export const generatePartnerOrderQuotaUrlApiSchema = z.object({
  externalPaymentUrl: z.string(),
})

export const listPartnerInvoicesApiSchema = z.array(partnerBillingInvoiceApiSchema)

// Types

export type PartnerBillingInvoiceApi = z.infer<typeof partnerBillingInvoiceApiSchema>
