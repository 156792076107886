import {
  loginWithIdp,
  reloadUser,
  verifyEmail,
  verifyEmailChange,
  verifyJoinPartnerToken,
  verifyJoinOrganizationToken,
} from '@/api/authentification'
import { i18n } from '@/plugins/i18n'
import { useAppStore } from '@/stores/app'
import { Action, Subject } from '@/types/acl'
import iziToast from 'izitoast'
import { RouteRecordRaw } from 'vue-router'

export default <RouteRecordRaw[]>[
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/pages/LoginPage.vue'),
    meta: {
      layout: 'auth',
      subject: Subject.AUTH,
      action: Action.READ,
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/sso/callback',
    name: 'sso-callback',
    meta: {
      layout: 'auth',
      subject: Subject.AUTH,
      action: Action.READ,
      redirectIfLoggedIn: true,
    },
    component: () => import('@/pages/LoginPage.vue'),
    beforeEnter: async (to, _, next) => {
      if (to.query.errorCode) {
        iziToast.error({
          message: i18n.global.t('errors.sso.' + to.query.errorCode.toString().toLowerCase()),
        })

        if (to.query.errorCode === 'UNKNOWN_ERROR') {
          // error Sentry
        }

        return next()
      }

      try {
        await loginWithIdp(to.query.token as string)

        next({ name: 'dispatcher' })
      } catch {
        iziToast.error({ message: i18n.global.t('errors.invitation') })
        next({ name: 'auth-login' })
      }
    },
  },
  {
    name: 'auth-register-organization',
    path: '/register',
    component: () => import('@/pages/RegisterOrganizationPage.vue'),
    meta: {
      layout: 'auth',
      subject: Subject.AUTH,
      action: Action.READ,
      redirectIfLoggedIn: true,
    },
  },
  {
    name: 'auth-register-partner',
    path: '/register-partner',
    component: () => import('@/pages/RegisterPartnerPage.vue'),
    meta: {
      layout: 'auth',
      subject: Subject.AUTH,
      action: Action.READ,
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/welcome',
    name: 'auth-verify',
    component: () => import('@/pages/VerifyPage.vue'),
    meta: {
      layout: 'auth',
      subject: Subject.AUTH,
      action: Action.READ,
    },
  },
  {
    path: '/join-organization',
    name: 'auth-join-organization',
    component: () => import('@/pages/JoinOrganizationPage.vue'),
    meta: {
      layout: 'auth',
      subject: Subject.AUTH,
      action: Action.READ,
      redirectIfLoggedIn: true,
    },
    props: (route) => ({
      token: route.query.token,
    }),
    beforeEnter: async (to, _, next) => {
      try {
        const { invitee, organization } = await verifyJoinOrganizationToken({
          token: to.query.token as string,
        })

        to.query.organization = organization as any
        to.query.invitee = invitee as any

        next()
      } catch {
        iziToast.error({ message: i18n.global.t('errors.invitation') })

        next({ name: 'auth-login' })
      }
    },
  },
  {
    path: '/join-partner',
    name: 'auth-join-partner',
    component: () => import('@/pages/JoinPartnerPage.vue'),
    meta: {
      layout: 'auth',
      subject: Subject.AUTH,
      action: Action.READ,
      redirectIfLoggedIn: true,
    },
    props: (route) => ({
      token: route.query.token,
    }),
    beforeEnter: async (to, _, next) => {
      try {
        const { partner, invitee } = await verifyJoinPartnerToken({
          token: to.query.token as string,
        })

        to.query.partner = partner as any
        to.query.invitee = invitee as any

        next()
      } catch {
        iziToast.error({ message: i18n.global.t('errors.invitation') })

        next({ name: 'auth-login' })
      }
    },
  },
  {
    path: '/mfa/enroll',
    name: 'auth-mfa-enroll',
    component: () => import('@/pages/MfaEnrollPage.vue'),
    meta: {
      layout: 'auth',
      subject: Subject.AUTH,
      action: Action.READ,
    },
  },
  {
    path: '/auth',
    name: 'auth-action',
    meta: {
      layout: 'auth',
      subject: Subject.AUTH,
      action: Action.READ,
    },
    beforeEnter: async (to, from, next) => {
      const appStore = useAppStore()

      const { mode, token } = to.query

      if (!mode) {
        next({ name: '404' })
      }

      switch (mode) {
        case 'verifyUserNewEmail': {
          try {
            await verifyEmailChange(token as string)

            appStore.destroy()
            iziToast.success({ message: i18n.global.t('verified') })
          } catch (error: any) {
            iziToast.error({ message: error.message })
          }

          next({ name: 'auth-login' })
          break
        }

        case 'verifyEmail': {
          try {
            await verifyEmail(token as string)
            await reloadUser()

            await appStore.init()

            iziToast.success({ message: i18n.global.t('verified') })
            next({ name: 'dashboard' })
          } catch (error: any) {
            iziToast.error({ message: error.message })

            next({ name: 'auth-login' })
          }
          break
        }
        case 'resetPassword': {
          next({ name: 'auth-reset-password', query: to.query })
          break
        }
      }
    },
  },
  {
    path: '/forgot-password',
    name: 'auth-forgot-password',
    component: () => import('./AuthForgotPassword/AuthForgotPassword.vue'),
    meta: {
      layout: 'auth',
      subject: Subject.AUTH,
      action: Action.READ,
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/reset-password',
    name: 'auth-reset-password',
    component: () => import('./AuthResetPassword/AuthResetPassword.vue'),
    meta: {
      layout: 'auth',
      subject: Subject.AUTH,
      action: Action.READ,
      redirectIfLoggedIn: true,
    },
  },
]
