import { apiFetch } from '@/utils/api'
import { throwApiError, validateObject } from '@/utils/api-data'
import { z } from 'zod'

export const organizationRepository = {
  async disable() {
    try {
      await apiFetch('/cyber-threat-intelligence/disable', {
        method: 'POST',
      })
    } catch (error) {
      return throwApiError(error)
    }
  },

  async enable(domains: string[]) {
    try {
      await apiFetch('/cyber-threat-intelligence/enable', {
        method: 'POST',
        body: JSON.stringify({ domains }),
      })
    } catch (error) {
      return throwApiError(error)
    }
  },

  async isCyberThreatIntelligenceEnabled() {
    try {
      const response = await apiFetch('/cyber-threat-intelligence')
      return validateObject(IsCyberThreatIntelligenceEnabledHttpResponseSchema, response)
    } catch (error) {
      return throwApiError(error)
    }
  },
}

export const IsCyberThreatIntelligenceEnabledHttpResponseSchema = z.object({
  isCyberThreatIntelligenceEnabled: z.boolean(),
})
