<script setup lang="ts">
import iziToast from 'izitoast'
import { useAppStore } from '@/stores/app.js'

// I18n
const { t } = useI18n()

// Web client update
const appStore = useAppStore()

watch(
  () => appStore.webClientIsOutdated,
  (value) => {
    if (value) {
      iziToast.show({
        message: t('new-version'),
        timeout: false,
        backgroundColor: 'black',
        buttons: [
          [
            `<button class="v-btn v-btn--density-default" style="background-color:#7368F0" onclick="location.reload()">${t(
              'refresh',
            )}</button>`,
            () => {},
            false,
          ],
        ],
      })
    }
  },
  { immediate: true },
)
</script>

<template>
  <div />
</template>

<i18n lang="json">
{
  "en": {
    "new-version": "A new version of Arsen is available",
    "refresh": "Refresh"
  }
}
</i18n>
