import { Action, Subject } from '@/types/acl'
import type { RouteRecordRaw } from 'vue-router'

export default <RouteRecordRaw[]>[
  {
    /**
     * @deprecated `/organizations` is deprecated in favor of `/partner/dashboard`
     * Keeping this route for backward compatibility.
     * It can safely be removed in the future.
     */
    path: '/organizations',
    redirect: {
      name: 'partner-dashboard',
    },
    meta: {
      subject: Subject.PARTNER_ORGANIZATIONS,
      action: Action.LIST,
    },
  },
  {
    path: '/partner',
    name: 'partner',
    redirect: {
      name: 'partner-dashboard',
    },
    meta: {
      subject: Subject.PARTNER_ORGANIZATIONS,
      action: Action.LIST,
    },
    children: [
      {
        path: 'dashboard',
        name: 'partner-dashboard',
        component: () => import('@/pages/PartnerDashboardPage.vue'),
        meta: {
          subject: Subject.PARTNER_ORGANIZATIONS,
          action: Action.LIST,
        },
      },
      {
        path: 'settings',
        name: 'partner-settings',
        component: () => import('@/pages/PartnerSettingsPage.vue'),
        redirect: {
          name: 'partner-settings-general',
        },
        meta: {
          subject: Subject.PARTNER_SETTINGS,
          action: Action.UPDATE,
        },
        children: [
          {
            path: 'general',
            name: 'partner-settings-general',
            component: () => import('./PartnerSettings/components/PartnerSettingsGeneral'),
            meta: {
              subject: Subject.PARTNER_SETTINGS,
              action: Action.UPDATE,
            },
          },
          {
            path: 'security',
            name: 'partner-settings-security',
            component: () => import('./PartnerSettings/components/PartnerSettingsSecurity'),
            meta: {
              subject: Subject.PARTNER_SECURITY,
              action: Action.UPDATE,
            },
          },
          {
            path: 'credits',
            name: 'partner-settings-credits',
            component: () => import('./PartnerSettings/components/PartnerSettingsCredits'),
            meta: {
              subject: Subject.PARTNER_BILLING,
              action: Action.MANAGE,
            },
          },
          {
            path: 'team',
            name: 'partner-team-list',
            component: () => import('./PartnerSettings/components/PartnerTeam'),
            meta: {
              subject: Subject.PARTNER_SETTINGS,
              action: Action.READ,
            },
          },
        ],
      },
    ],
  },
]
