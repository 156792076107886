import { z } from 'zod'

export const MicrosoftPhishReportArsenAddInDetailsSchema = z.object({
  forwardTo: z.string().optional(),
  translations: z.object({
    english: z.object({
      nonSimulatedThankYouMessage: z.string(),
      reportButtonText: z.string(),
      simulatedThankYouMessage: z.string(),
    }),
    french: z.object({
      nonSimulatedThankYouMessage: z.string(),
      reportButtonText: z.string(),
      simulatedThankYouMessage: z.string(),
    }),
  }),
})

export const WorkOsDetailsSchema = z.object({
  organizationId: z.string().nullable(),
  isGranted: z.boolean(),
  directory: z.string().nullable(),
})

export const MicrosoftIdentityDirectoryDetailsSchema = z.object({
  scope: z.string(),
  groupIds: z.array(z.string()).default([]),
})

export const GoogleIdentityDirectoryDetailsSchema = z.object({
  scope: z.string(),
  groupIds: z.array(z.string()).default([]),
})

export const EmptyDetailsSchema = z.object({})

export type MicrosoftPhishReportArsenAddInDetails = z.infer<
  typeof MicrosoftPhishReportArsenAddInDetailsSchema
>

export type MicrosoftPhishReportBuiltInButtonDetails = z.infer<typeof EmptyDetailsSchema>

export type GooglePhishReportArsenAddOnDetails = z.infer<typeof EmptyDetailsSchema>

export type MicrosoftIdentityDirectoryDetails = z.infer<
  typeof MicrosoftIdentityDirectoryDetailsSchema
>

export type GoogleIdentityDirectoryDetails = z.infer<typeof GoogleIdentityDirectoryDetailsSchema>

export type WorkOsDetails = z.infer<typeof WorkOsDetailsSchema>

const integrationSchema = z.discriminatedUnion('type', [
  z.object({
    type: z.literal('MICROSOFT_PHISH_REPORT_ARSEN_ADDIN'),
    enabled: z.boolean(),
    details: MicrosoftPhishReportArsenAddInDetailsSchema,
  }),
  z.object({
    type: z.literal('MICROSOFT_PHISH_REPORT_BUILTIN_BUTTON'),
    enabled: z.boolean(),
    details: EmptyDetailsSchema,
  }),
  z.object({
    type: z.literal('GOOGLE_PHISH_REPORT_ARSEN_ADDON'),
    enabled: z.boolean(),
    details: EmptyDetailsSchema,
  }),
  z.object({
    type: z.literal('MICROSOFT_EMAIL_DELIVERY'),
    enabled: z.boolean(),
    details: EmptyDetailsSchema,
  }),
  z.object({
    type: z.literal('GOOGLE_EMAIL_DELIVERY'),
    enabled: z.boolean(),
    details: EmptyDetailsSchema,
  }),
  z.object({
    type: z.literal('MICROSOFT_IDENTITY_DIRECTORY'),
    enabled: z.boolean(),
    details: MicrosoftIdentityDirectoryDetailsSchema,
  }),
  z.object({
    type: z.literal('GOOGLE_IDENTITY_DIRECTORY'),
    enabled: z.boolean(),
    details: GoogleIdentityDirectoryDetailsSchema,
  }),
  z.object({
    type: z.literal('WORK_OS'),
    enabled: z.boolean(),
    details: WorkOsDetailsSchema,
  }),
])

export const integrationsSchema = z.array(integrationSchema)

export type Integrations = z.infer<typeof integrationsSchema>
export type Integration = z.infer<typeof integrationSchema>
