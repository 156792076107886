import { RouteRecordRaw } from 'vue-router'
import { organizationRepository } from '../infra/adapters/organization.repository'
import { breachesRoute } from '../use-cases/breaches/route'
import { domainMonitoringRoute } from '../use-cases/domain-monitoring/route'
import {
  breachesFeatureWallRoute,
  domainMonitoringFeatureWallRoute,
} from './views/featureWall/route'

export const cyberThreatIntelligenceRoutes: RouteRecordRaw[] = [
  {
    name: 'breaches',
    path: '/breaches',
    redirect: { name: breachesRoute.name },
    beforeEnter: async (to, _from, next) => {
      const { isCyberThreatIntelligenceEnabled } =
        await organizationRepository.isCyberThreatIntelligenceEnabled()

      const shouldNavigateToFeatureWall =
        !isCyberThreatIntelligenceEnabled && to.name !== breachesFeatureWallRoute.name
      const shouldNavigateToBreaches =
        isCyberThreatIntelligenceEnabled && to.name === breachesFeatureWallRoute.name

      if (shouldNavigateToFeatureWall) {
        next({ name: breachesFeatureWallRoute.name })
      } else if (shouldNavigateToBreaches) {
        next({ name: breachesRoute.name })
      } else {
        next()
      }
    },
    children: [breachesRoute, breachesFeatureWallRoute],
  },
  {
    name: 'domain-monitoring',
    path: '/domain-monitoring',
    redirect: { name: domainMonitoringRoute.name },
    beforeEnter: async (to, _from, next) => {
      const { isCyberThreatIntelligenceEnabled } =
        await organizationRepository.isCyberThreatIntelligenceEnabled()

      const shouldNavigateToFeatureWall =
        !isCyberThreatIntelligenceEnabled && to.name !== domainMonitoringFeatureWallRoute.name
      const shouldNavigateToDomainMonitoring =
        isCyberThreatIntelligenceEnabled && to.name === domainMonitoringFeatureWallRoute.name

      if (shouldNavigateToFeatureWall) {
        next({ name: domainMonitoringFeatureWallRoute.name })
      } else if (shouldNavigateToDomainMonitoring) {
        next({ name: domainMonitoringRoute.name })
      } else {
        next()
      }
    },
    children: [domainMonitoringRoute, domainMonitoringFeatureWallRoute],
  },
]
