import { z } from 'zod'

export enum OrganizationMemberPermission {
  OWNER = 'ownerAttributes',
  ADMIN = 'adminAttributes',
  VIEWER = 'viewerAttributes',
}

export enum PartnerMemberPermission {
  OWNER = 'partnerOwnerAttributes',
  ADMIN = 'partnerAdminAttributes',
}

export enum TenantAccessType {
  PARTNER = 'PARTNER',
  ORGANIZATION = 'ORGANIZATION',
}

export const userSchema = z.object({
  id: z.string(),
  email: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  language: z.string().nullable(),
})
export type User = z.infer<typeof userSchema>
